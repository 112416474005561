import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  CircularProgress,
  Tooltip,
  List,
  ListItemButton,
  ListItemIcon,
  Collapse,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { VscFeedback } from "react-icons/vsc";
import { HiOutlineHome } from "react-icons/hi2";
import { FaRegNoteSticky } from "react-icons/fa6";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import { SiMoneygram } from "react-icons/si";
import { TbHome2 } from "react-icons/tb";
import { IoBagOutline } from "react-icons/io5";
import { TiCloudStorageOutline } from "react-icons/ti";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import { useSelector } from "react-redux";
import SnackBar from "../snackBar";
import { FaStethoscope } from "react-icons/fa6";
import "./style.css";
import logo from "../../images/logo2.png";
import { useDispatch } from "react-redux";
import { clearSnack, clientCheck } from "../../utils/utilFunctions";
import SportsIcon from "@mui/icons-material/Sports";

import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const Sidebar = ({
  sidebarMobileView,
  toggleDrawer,
  setSidebarMenu,
  sidebarMenu,
}) => {
  const { user } = useSelector((state) => state.userReducer);
  const { isCollapsed } = useSelector((state) => state.companyReducer);

  const {
    openSnackbar,
    snackType: snackType2,
    message: message2,
  } = useSelector((state) => state.snackbarReducer);

  const {
    isDashboard,
    isGoalsAndPlanning,
    isGuidedCoaching,
    isFinancialData,
    isInsightAndAnalysis,
    isReports,
  } = useSelector((state) => state.sidenavReducer);

  const { company } = useSelector((state) => state.companyDetailReducer);
  const dispatch = useDispatch();

  const { selectedCompany } = useSelector((state) => state.companyReducer);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let token = localStorage.getItem("token");
    let PStatus = localStorage.getItem("paymentStatus");
    let cpcp_subscribed = localStorage.getItem("cpcp_subscribed");
    let lastLoginTime = localStorage.getItem("last_login_time");
    const currentTime = new Date();

    if (
      !lastLoginTime ||
      currentTime - new Date(lastLoginTime) > 24 * 60 * 60 * 1000
    ) {
      localStorage.clear();
      dispatch({
        type: "LOGOUT",
      });
      navigate("/");
    }

    if (
      (!token || token === "undefined") &&
      (!PStatus || PStatus !== "true") &&
      (!cpcp_subscribed || cpcp_subscribed !== "true")
    ) {
      localStorage.clear();
      dispatch({
        type: "LOGOUT",
      });
      navigate("/");
    } else if (PStatus === "false" && cpcp_subscribed === "false") {
      navigate("/subscription");
    } else if (!Object.keys(company).length) {
      if (location.pathname !== "/company-detail") navigate("/home");
    }
  }, [navigate]);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  // const sidebarTabs = [
  //   {
  //     section: "Dashboard",
  //     open: isDashboard,
  //     content: [
  //       {
  //         icon: HiOutlineHome,
  //         label: "Home",
  //         route: "/home",
  //         disabled: false,
  //       },
  //     ],
  //   },
  //   {
  //     section: "Goals & Planning",
  //     open: isGoalsAndPlanning,

  //     content: [
  //       {
  //         icon: PauseCircleOutlineIcon,
  //         label: "Start With End In Mind",
  //         route: "/start-with-end-in-mind",
  //         disabled: false,
  //       },
  //     ],
  //   },
  //   {
  //     section: "Guided Coaching",
  //     open: isGuidedCoaching,

  //     content: [
  //       {
  //         icon: SportsIcon,
  //         label: "Client Conversation Coach",
  //         route: "/coach",
  //         disabled: user?.isClient ? true : false,
  //       },
  //     ],
  //   },
  //   {
  //     section: "Financial Data",
  //     open: isFinancialData,

  //     content: [
  //       {
  //         icon: FaRegNoteSticky,
  //         label: "Financial Data",
  //         route: "/financial-data",
  //         disabled: user.isClient,
  //       },
  //     ],
  //   },
  //   {
  //     section: "Insight & Analysis",
  //     open: isInsightAndAnalysis,
  //     content: [
  //       {
  //         icon: HiOutlineCurrencyDollar,
  //         label: "Hidden Cash",
  //         route: "/hidden-cash",
  //         disabled: false,
  //       },
  //       {
  //         icon: SiMoneygram,
  //         label: "Fast Money Formula",
  //         route: "/fast-money-formula",
  //         disabled: false,
  //       },
  //       {
  //         icon: TbHome2,
  //         label: "Home Run",
  //         route: "/home-run",
  //         disabled: false,
  //       },
  //       {
  //         icon: IoBagOutline,
  //         label: "Simple Valuation",
  //         route: "/simple-valuation",
  //         disabled: false,
  //       },
  //       {
  //         icon: FaStethoscope,
  //         label: "Financial Doctor",
  //         route: "/financial-doctor",
  //         disabled: false,
  //       },
  //     ],
  //   },

  //   {
  //     section: "Reports",
  //     open: isReports,
  //     content: [
  //       {
  //         icon: TiCloudStorageOutline,
  //         label: "Create PDF",
  //         route: "/pdf",
  //         disabled: graphLoading || user.isClient,
  //       },
  //     ],
  //   },
  // ];

  // const sidebarMobileView = [
  //   {
  //     icon: HiOutlineHome,
  //     label: "Home",
  //     route: "/home",
  //     disabled: false,
  //   },
  //   {
  //     icon: SportsIcon,
  //     label: "Client Conversation Coach",
  //     route: "/coach",
  //     disabled: user?.isClient ? true : false,
  //   },
  //   {
  //     icon: PauseCircleOutlineIcon,
  //     label: "Start With End In Mind",
  //     route: "/start-with-end-in-mind",
  //     disabled: false,
  //   },
  //   {
  //     icon: FaRegNoteSticky,
  //     label: "Financial Data",
  //     route: "/financial-data",
  //     disabled: user.isClient,
  //   },
  //   {
  //     icon: HiOutlineCurrencyDollar,
  //     label: "Hidden Cash",
  //     route: "/hidden-cash",
  //     disabled: false,
  //   },
  //   {
  //     icon: SiMoneygram,
  //     label: "Fast Money Formula",
  //     route: "/fast-money-formula",
  //     disabled: false,
  //   },
  //   {
  //     icon: TbHome2,
  //     label: "Home Run",
  //     route: "/home-run",
  //     disabled: false,
  //   },
  //   {
  //     icon: IoBagOutline,
  //     label: "Simple Valuation",
  //     route: "/simple-valuation",
  //     disabled: false,
  //   },
  //   {
  //     icon: FaStethoscope,
  //     label: "Financial Doctor",
  //     route: "/financial-doctor",
  //     disabled: false,
  //   },
  //   {
  //     icon: TiCloudStorageOutline,
  //     label: "Create PDF",
  //     route: "/pdf",
  //     disabled: graphLoading || user.isClient,
  //   },
  // ];

  // useEffect(() => {
  //   sidebarTabs[5].content[0] = {
  //     icon: TiCloudStorageOutline,
  //     label: "Create PDF",
  //     route: "/pdf",
  //     disabled: graphLoading || user.isClient,
  //   };
  // }, [graphLoading, user.isClient]);

  // const [sidebarMenu, setSidebarMenu] = useState(sidebarTabs);

  const handleNavigate = (route) => {
    if (
      Object.keys(selectedCompany).length ||
      route === "/home" ||
      route === "/contact-us"
    )
      return navigate(route);

    dispatch({
      type: "OPEN_SNACK",
      payload: {
        snackType: "error",
        message: "Please Select Company First",
      },
    });
    clearSnack(dispatch);
    return;
  };

  const handleCategoryCollapse = (index) => {
    switch (index) {
      case 0:
        dispatch({
          type: "UPDATE_STATE",
          payload: {
            category: "isDashboard",
            value: !isDashboard,
          },
        });
        break;

      case 1:
        dispatch({
          type: "UPDATE_STATE",
          payload: {
            category: "isGoalsAndPlanning",
            value: !isGoalsAndPlanning,
          },
        });
        break;

      case 2:
        dispatch({
          type: "UPDATE_STATE",
          payload: {
            category: "isGuidedCoaching",
            value: !isGuidedCoaching,
          },
        });
        break;

      case 3:
        dispatch({
          type: "UPDATE_STATE",
          payload: {
            category: "isFinancialData",
            value: !isFinancialData,
          },
        });
        break;

      case 4:
        dispatch({
          type: "UPDATE_STATE",
          payload: {
            category: "isInsightAndAnalysis",
            value: !isInsightAndAnalysis,
          },
        });
        break;

      case 5:
        dispatch({
          type: "UPDATE_STATE",
          payload: {
            category: "isReports",
            value: !isReports,
          },
        });
        break;

      default:
        break;
    }

    const dummySidenav = [...sidebarMenu];
    dummySidenav[index].open = !dummySidenav[index].open;
    setSidebarMenu(dummySidenav);
  };

  return (
    <React.Fragment>
      <Grid
        className="sidebar_main"
        style={{
          transition: "width 0.3s",
          width: isCollapsed ? "90px" : "100%",
          position: "relative",
        }}
      >
        <Box className="sidebar_ThomsonLogo">
          {!isCollapsed && (
            <img
              src={user.logo ? user.logo : logo}
              alt="Logo"
              className="sidebar_ThomsonLogo"
              onClick={() => handleNavigate("/home")}
            />
          )}
          {isCollapsed && (
            <KeyboardDoubleArrowRightIcon
              onClick={toggleDrawer}
              style={{
                color: "#b4b4b4",
                cursor: "pointer",
                margin: "20px",
                fontSize: "30px",
              }}
            />
          )}
        </Box>
        <List
          sx={{ width: "100%", bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {!isCollapsed
            ? sidebarMenu?.map((section, index) => (
                <>
                  <ListItemButton
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() => handleCategoryCollapse(index)}
                  >
                    <ListItemIcon sx={{ fontWeight: "700", color: "#201b5b" }}>
                      {section.section}
                    </ListItemIcon>

                    {section.open ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  {section.content.map((tab, index) => (
                    <>
                      <Collapse in={section.open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <Box
                            className={` ${
                              user.isClient && tab?.disabled
                                ? "disabled_sidebar_tab"
                                : "sidebar_tab"
                            } ${
                              window.location.pathname === tab?.route &&
                              "sidebar_tab_active"
                            } `}
                            sx={{ marginTop: "10px" }}
                            key={index}
                            onClick={() => {
                              if (user.isClient && tab?.disabled)
                                clientCheck(user, dispatch);
                              if (!tab.disabled) handleNavigate(tab?.route);
                            }}
                          >
                            <tab.icon
                              style={{ marginRight: 10, fontSize: 19 }}
                            />
                            {!isCollapsed && <div>{tab?.label}</div>}
                            {tab.route === "/pdf" &&
                            tab.disabled &&
                            !user.isClient ? (
                              <CircularProgress
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  marginLeft: "5%",
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Box>
                        </List>
                      </Collapse>
                    </>
                  ))}
                </>
              ))
            : sidebarMobileView?.map((tab, index) => (
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: "none",
                        backgroundColor: "#F8F8FF",
                        color: "gray",
                        paddingY: "10px",
                        paddingX: "20px",
                        display: !isCollapsed ? "none" : "",
                      },
                    },
                  }}
                  title={
                    <pre style={{ fontSize: "13px", fontWeight: "600" }}>
                      {isCollapsed ? tab?.label : ""}
                    </pre>
                  }
                  placement="right"
                >
                  <Box
                    className={` ${
                      user.isClient && tab?.disabled
                        ? "disabled_sidebar_tab"
                        : "sidebar_tab"
                    } ${
                      window.location.pathname === tab?.route &&
                      "sidebar_tab_active"
                    } `}
                    key={index}
                    onClick={() => {
                      if (user.isClient && tab?.disabled)
                        clientCheck(user, dispatch);
                      if (!tab.disabled) handleNavigate(tab?.route);
                    }}
                  >
                    <tab.icon style={{ marginRight: 10, fontSize: 19 }} />
                    {!isCollapsed && <div>{tab?.label}</div>}
                    {tab.route === "/pdf" && tab.disabled && !user.isClient ? (
                      <CircularProgress
                        style={{
                          height: "20px",
                          width: "20px",
                          marginLeft: "5%",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Tooltip>
              ))}
        </List>
        <Box
          style={{
            position: "fixed",
            bottom: 0,
            left: 10,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            onClick={() => handleNavigate("/contact-us")}
            className="contact_us"
            style={{
              textAlign: "center",
            }}
          >
            <VscFeedback style={{ marginRight: 10, fontSize: 25 }} />
            {!isCollapsed && <div>Contact us</div>}
          </Box>

          <Box
            onClick={() =>
              window.open(
                "https://cashflowmike.com/appdocs/getting-started-with-the-clear-path-to-cash-app/"
              )
            }
            className="knowledge_base"
            style={{
              textAlign: "center",
              gap: "10px",
            }}
          >
            <InfoOutlinedIcon />
            {!isCollapsed && <div>Knowledge Base</div>}
          </Box>
        </Box>

        {/* {!isCollapsed && (
          <KeyboardTabIcon
            style={{
              position: "absolute",
              left: "95%",
              top: "4vh",
              transform: "translateY(-50%) scaleX(-1)",
              color: "#b4b4b4",
              border: "none",
              borderRadius: "50%",
              height: "30px",
              width: "30px",
              cursor: "pointer",
              background: "white",
              fontWeight: "300",
            }}
            onClick={toggleDrawer}
          />
        )} */}
      </Grid>
      <SnackBar
        // setOpen={setOpenSnackBar}
        open={openSnackbar}
        snackType={snackType2}
        msg={message2}
      />
      {/* <SnackBar
        setOpen={setOpen}
        open={open}
        snackType={"error"}
        msg={message}
      /> */}
    </React.Fragment>
  );
};
export default Sidebar;
