import React, { useState, useEffect, useRef } from "react";
import { Layout } from "../../layout";
import { CardWrapper, Accordian } from "../../components";
import {
  Grid,
  Typography,
  Box,
  Divider,
  Select,
  MenuItem,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import Analytics from "../../assets/analytics-icon.svg";
import IOSSwitch from "../../components/Toggle/index.js";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useDispatch, useSelector } from "react-redux";
import { fetchYears } from "../../redux/actions/homeRun";
import html2canvas from "html2canvas";
import { updatePDFData } from "../../redux/actions/pdfGraph.js";
import { handlInfo, combineImages } from "../../utils/utilFunctions.js";
import ResponsiveGrid from "./horizontalGuage/horizontalGaugeChart.jsx";
import CustomAppBarDebtEquityRatio from "./horizontalGuage/debtEquityRatio.jsx";
import CurrentRatioChart from "../../components/financialDoctor/graphs/currentRatioGraph.jsx";
import CustomAppBarGrossMargin from "./horizontalGuage/grossMargin.jsx";
import QuickRatioChart from "../../components/financialDoctor/graphs/quickRatioGraph.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fetchIndustryAvg } from "../../redux/actions/company.js";
import { fetchHistoricalVals } from "../../redux/actions/financialDoctor.js";
import { fetchFinancialDoctorNotes } from "../../redux/actions/notes.js";
import MissingValTooltip from "../../components/financialDoctor/missingValTooltip.jsx";
import TooltipMenu from "./gradeMenu.jsx";
import {
  PERIOD_DURATION,
  CURRENT_RATIO,
  DEDT_EQUITY_RATIO,
  GROSS_MARGIN,
  QUICK_RATIO,
  LOW,
  AVERAGE,
  HIGH,
  NET_MARGIN,
  SALES_TO_ASSETS,
  EBITDA,
  ROA,
  ROI,
  INVENTORY_TURNOVER,
  AR_TURNOVER,
  AP_TURNOVER,
  DCOH,
  DSCR,
  FDCalList,
} from "../../utils/constants.js";
import {
  getComparisonDetails,
  calculateValuesForApTurnover,
  calculateValuesForRoi,
  calculateValuesForRoa,
  calculateValuesForEbitda,
  calculateValuesForSalesToAssets,
  calculateValuesForNetMargin,
  calculateValuesForGrossMargin,
  calculateValuesForArTurnover,
  calculateValuesForInventoryTurnover,
  calculateValuesForDebtEquityRatio,
  calculateValuesForQuickRatio,
  calculateValuesForCurrentRatio,
} from "../../utils/utilFunctions.js";
import CustomAppBarNetMargin from "./horizontalGuage/netMargin.jsx";
import CustomAppBarSalesToAssets from "./horizontalGuage/salesToAssets.jsx";
import CustomAppBarEbitda from "./horizontalGuage/ebitda.jsx";
import CustomAppBarRoa from "./horizontalGuage/roa.jsx";
import CustomAppBarRoi from "./horizontalGuage/roi.jsx";
import CustomAppBarInventoryTurnover from "./horizontalGuage/inventoryTurnover.jsx";
import { clearSnack } from "../../utils/utilFunctions.js";
import SnackBar from "../../components/snackBar";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const FinancialDoctor = () => {
  const {
    currentRatioNotes,
    quickRatioNotes,
    debtEqRatioNotes,
    grossMarginNotes,
    netMarginNotes,
    saleAssetsNotes,
    ebidtaFDNotes,
    roaNotes,
    roiNotes,
    invTurnoverNotes,
    arTurnoverNotes,
    apTurnoverNotes,
    dcohNotes,
    dscrNotes,
  } = useSelector((state) => state.notesReducer);

  const { openSnackbar, snackType, message } = useSelector(
    (state) => state.snackbarReducer
  );

  const topBarRef = useRef(null);

  // notes ref
  const currentRatioNotesRef = useRef(null);
  const quickRatioNotesRef = useRef(null);
  const debtEqRatioNotesRef = useRef(null);
  const grossMarginNotesRef = useRef(null);
  const netMarginNotesRef = useRef(null);
  const saleAssetsNotesRef = useRef(null);
  const ebidtaFDNotesRef = useRef(null);
  const roaNotesRef = useRef(null);
  const roiNotesRef = useRef(null);
  const invTurnoverNotesRef = useRef(null);
  const arTurnoverNotesRef = useRef(null);
  const apTurnoverNotesRef = useRef(null);
  const dcohNotesRef = useRef(null);
  const dscrNotesRef = useRef(null);

  const currentRatioSection = useRef(null);
  const quickRatioSection = useRef(null);
  const debtEqRatioSection = useRef(null);
  const grossMarginSection = useRef(null);
  const netMarginSection = useRef(null);
  const salesToAssetsSection = useRef(null);
  const ebidtaSection = useRef(null);
  const roaSection = useRef(null);
  const roiSection = useRef(null);
  const invTurnoverSection = useRef(null);
  const arTurnoverSection = useRef(null);
  const apTurnoverSection = useRef(null);
  const dcohSection = useRef(null);
  const dscrSection = useRef(null);

  // guage ref
  const currentRatioGuageRef = useRef(null);
  const quickRatioGuageRef = useRef(null);
  const debtEqRatioGuageRef = useRef(null);
  const grossMarginGuageRef = useRef(null);
  const netMarginGuageRef = useRef(null);
  const saleAssetsGuageRef = useRef(null);
  const ebidtaFDGuageRef = useRef(null);
  const roaGuageRef = useRef(null);
  const roiGuageRef = useRef(null);
  const invTurnoverGuageRef = useRef(null);
  const arTurnoverGuageRef = useRef(null);
  const apTurnoverGuageRef = useRef(null);
  const dcohGuageRef = useRef(null);
  const dscrGuageRef = useRef(null);

  // chart ref
  const currentRatioChartRef = useRef(null);
  const quickRatioChartRef = useRef(null);
  const debtEqRatioChartRef = useRef(null);
  const grossMarginChartRef = useRef(null);
  const netMarginChartRef = useRef(null);
  const saleAssetsChartRef = useRef(null);
  const ebidtaFDChartRef = useRef(null);
  const roaChartRef = useRef(null);
  const roiChartRef = useRef(null);
  const invTurnoverChartRef = useRef(null);
  const arTurnoverChartRef = useRef(null);
  const apTurnoverChartRef = useRef(null);
  const dcohChartRef = useRef(null);
  const dscrChartRef = useRef(null);

  // Report ref
  const currentRatioReportRef = useRef(null);
  const quickRatioReportRef = useRef(null);
  const debtEqRatioReportRef = useRef(null);
  const grossMarginReportRef = useRef(null);
  const netMarginReportRef = useRef(null);
  const saleAssetsReportRef = useRef(null);
  const ebidtaFDReportRef = useRef(null);
  const roaReportRef = useRef(null);
  const roiReportRef = useRef(null);
  const invTurnoverReportRef = useRef(null);
  const arTurnoverReportRef = useRef(null);
  const apTurnoverReportRef = useRef(null);
  const dcohReportRef = useRef(null);
  const dscrReportRef = useRef(null);

  const [dateType, setDateType] = useState("");
  const [indusAvgs, setIndusAvgs] = useState();

  const [dateLoading, setDateLoading] = useState(false);

  const [datePeriods, setDatePeriods] = useState("");

  const [historicalValues, setHistoricalValues] = useState();

  const [currentRatioScale, setCurrentRatioScale] = useState();
  const [currentRatio, setCurrentRatio] = useState();
  const [currentRatioGrade, setCurrentRatioGrade] = useState();
  const [currentRatioComparisonDetails, setCurrentRatioComparisonDetails] =
    useState();

  const [quickRatioSclae, setQuickRatioScale] = useState();
  const [quickRatio, setQuickRatio] = useState();
  const [quickRatioGrade, setQuickRatioGrade] = useState();
  const [quickRatioComparisonDetails, setQuickRatioComparisonDetails] =
    useState();

  const [debtEquityRatio, setDebtEquityRatio] = useState();
  const [debtEquityRatioGrade, setDebtEquityRatioGrade] = useState();
  const [
    debtEquityRatioComparisonDetails,
    setDebtEquityRatioComparisonDetails,
  ] = useState();
  const [debtEquityRatioScale, setDebtEquityRatioScale] = useState();

  const [inventoryTurnover, setInventoryTurnover] = useState();
  const [inventoryTurnoverGrade, setInventoryTurnoverGrade] = useState();
  const [
    inventoryTurnoverComparisonDetails,
    setInventoryTurnoverComparisonDetails,
  ] = useState();
  const [inventoryTurnoverScale, setInventoryTurnoverScale] = useState();

  const [arTurnover, setArTurnover] = useState();
  const [arTurnoverGrade, setArTurnoverGrade] = useState();
  const [arTurnoverComparisonDetails, setArTurnoverComparisonDetails] =
    useState();
  const [arTurnoverScale, setArTurnoverScale] = useState();

  const [grossMargin, setGrossMargin] = useState();
  const [grossMarginGrade, setGrossMarginGrade] = useState();
  const [grossMarginComparisonDetails, setGrossMarginComparisonDetails] =
    useState();
  const [grossMarginScale, setGrossMarginScale] = useState();

  const [netMargin, setNetMargin] = useState();
  const [netMarginGrade, setNetMarginGrade] = useState();
  const [netMarginComparisonDetails, setNetMarginComparisonDetails] =
    useState();
  const [netMarginScale, setNetMarginScale] = useState();

  const [salesToAssets, setSalesToAssets] = useState();
  const [salesToAssetsGrade, setSalesToAssetsGrade] = useState();
  const [salesToAssetsComparisonDetails, setSalesToAssetsComparisonDetails] =
    useState();
  const [salesToAssetsScale, setSalesToAssetsScale] = useState();

  const [ebidta, setEbidta] = useState();
  const [ebidtaGrade, setEbidtaGrade] = useState();
  const [ebidtaComparisonDetails, setEbidtaComparisonDetails] = useState();
  const [ebidtaScale, setEbidtaScale] = useState();

  const [roa, setRoa] = useState();
  const [roaGrade, setRoaGrade] = useState();
  const [roaComparisonDetails, setRoaComparisonDetails] = useState();
  const [roaScale, setRoaScale] = useState();

  const [roi, setRoi] = useState();
  const [roiGrade, setRoiGrade] = useState();
  const [roiComparisonDetails, setRoiComparisonDetails] = useState();
  const [roiScale, setRoiScale] = useState();

  const [apTurnover, setApTurnover] = useState();
  const [apTurnoverGrade, setApTurnoverGrade] = useState();
  const [apTurnoverComparisonDetails, setApTurnoverComparisonDetails] =
    useState();
  const [apTurnoverScale, setApTurnoverScale] = useState();

  const [dcoh, setDcoh] = useState();
  const [dcohGrade, setDcohGrade] = useState();
  const [dcohComparisonDetails, setDcohComparisonDetails] = useState();
  const dcohScale = [0, 5, 8, 10, 15, 20, 35, 40, 45, 50, 65, 70, 85];

  const [dscr, setDscr] = useState();
  const [dscrGrade, setDscrGrade] = useState();
  const [dscrComparisonDetails, setDscrComparisonDetails] = useState();
  const dscrScale = [
    0, 0.5, 0.9, 1, 1.06, 20, 1.25, 1.35, 1.44, 1.6, 1.65, 1.75, 85,
  ];

  const [missingValError, setMissingValError] = useState();

  const [selectedFinancialVals, setSelectFinancialVals] = useState({});

  const [isGraphUpdate, setIsGraphUpdate] = useState(false);

  const [shouldScroll, setShouldScroll] = useState(false);
  const [scrollTo, setScrollTo] = useState(false);

  const [useOwnerGoal, setUseOwnerGoal] = useState(false);

  const [avgVals, setAvgVals] = useState({
    salesToAssets: 0,
    grossProfitePercentage: 0,
    netProfitePercentage: 0,
    inventoryTurnoverRate: 0,
    accountsReceivableTurnoverRate: 0,
    accountsPayableTurnoverRate: 0,
    ebidta: 0,
    roa: 0,
    roi: 0,
    currentRatio: 0,
    quickRatio: 0,
    debtToEquityRatio: 0,
  });

  const dispatch = useDispatch();

  const { financialData, company } = useSelector(
    (state) => state.companyDetailReducer
  );

  const calculateValues = (avgVal) => {
    const currentRatioVal = calculateValuesForCurrentRatio(avgVal.currentRatio);
    const quickRatioVal = calculateValuesForQuickRatio(avgVal.quickRatio);
    const debtEquityRatioVal = calculateValuesForDebtEquityRatio(
      avgVal.debtToEquityRatio
    );

    const inventoryTurnoverVal = calculateValuesForInventoryTurnover(
      avgVal.inventoryTurnoverRate && parseInt(avgVal.inventoryTurnoverRate)
        ? parseInt((365 / parseInt(avgVal.inventoryTurnoverRate))?.toFixed(0))
        : 0
    );

    const arTurnoverVal = calculateValuesForArTurnover(
      avgVal.inventoryTurnoverRate && parseInt(avgVal.inventoryTurnoverRate)
        ? parseInt((365 / parseInt(avgVal.inventoryTurnoverRate))?.toFixed(0))
        : 0
    );

    const grossMarginVal = calculateValuesForGrossMargin(
      parseInt(avgVal.grossProfitePercentage)
    );
    const netMarginVal = calculateValuesForNetMargin(
      avgVal.netProfitePercentage
    );
    const salesToAssetsVal = calculateValuesForSalesToAssets(
      avgVal.salesToAssets
    );

    const apTurnoverVal = calculateValuesForApTurnover(
      parseInt((365 / avgVal.accountsPayableTurnoverRate)?.toFixed(0))
    );

    const ebidta = calculateValuesForEbitda(avgVal.ebidta);

    const roa = calculateValuesForRoa(avgVal.roa);

    const roi = calculateValuesForRoi(avgVal.roi);

    setCurrentRatioScale(currentRatioVal);
    setQuickRatioScale(quickRatioVal);
    setDebtEquityRatioScale(debtEquityRatioVal);
    setInventoryTurnoverScale(inventoryTurnoverVal);
    setArTurnoverScale(arTurnoverVal);
    setGrossMarginScale(grossMarginVal);
    setNetMarginScale(netMarginVal);
    setSalesToAssetsScale(salesToAssetsVal);
    setEbidtaScale(ebidta);
    setRoaScale(roa);
    setRoiScale(roi);
    setApTurnoverScale(apTurnoverVal);
  };

  const handleIndustryAvgCheck = (indusVal) => {
    const dummyAvgVals = {
      salesToAssets: indusVal["Sales To Assets"]
        ? indusVal["Sales To Assets"]
        : 0,
      grossProfitePercentage: indusVal["Gross Profit Percentage"]
        ? indusVal["Gross Profit Percentage"]
        : 0,
      netProfitePercentage: indusVal["Net Profit Percentage"]
        ? indusVal["Net Profit Percentage"]
        : 0,
      inventoryTurnoverRate: indusVal["Inventory Turnover Rate"]
        ? indusVal["Inventory Turnover Rate"]
        : 0,
      accountsReceivableTurnoverRate: indusVal["AR Turnover Rate"]
        ? indusVal["AR Turnover Rate"]
        : 0,
      accountsPayableTurnoverRate: indusVal["AP Turnover Rate"]
        ? indusVal["AP Turnover Rate"]
        : 0,
      ebidta: indusVal["EBITDA"] ? indusVal["EBITDA"] : 0,
      roa: indusVal["Return On Assets"] ? indusVal["Return On Assets"] : 0,
      roi: indusVal["Return On Investment"]
        ? indusVal["Return On Investment"]
        : 0,
      currentRatio: indusVal["Current Ratio"] ? indusVal["Current Ratio"] : 0,
      quickRatio: indusVal["Quick Ratio"] ? indusVal["Quick Ratio"] : 0,
      debtToEquityRatio: indusVal["Debt To Equity Ratio"]
        ? indusVal["Debt To Equity Ratio"]
        : 0,
    };

    setAvgVals(dummyAvgVals);

    calculateValues(dummyAvgVals);
  };

  const fetchIndusAvgs = async () => {
    if (company._id) {
      const payload = {
        companyId: company._id,
        NAICScode: company.NAICScode,
      };

      const resp = await dispatch(fetchIndustryAvg(payload));

      setIndusAvgs(resp);

      handleIndustryAvgCheck(resp);
    }
  };

  useEffect(() => {
    fetchIndusAvgs();
  }, [company]);

  const handleCurrentRatioGrade = (
    dummyCurrentRatio,
    currentRatioTenPercentLess,
    currentRationThirtyThreePercentHigh,
    financialVals,
    grade
  ) => {
    if (
      (dummyCurrentRatio > currentRatioTenPercentLess &&
        dummyCurrentRatio < currentRationThirtyThreePercentHigh) ||
      grade === AVERAGE
    ) {
      setCurrentRatioGrade("Fair");
      setCurrentRatioComparisonDetails(
        getComparisonDetails(CURRENT_RATIO, AVERAGE, financialVals, {
          currentRatioInusVal: avgVals?.currentRatio,
          arTurnoverRate: parseInt(avgVals?.accountsReceivableTurnoverRate),
        })
      );
    } else if (
      dummyCurrentRatio < currentRatioTenPercentLess ||
      grade === LOW
    ) {
      setCurrentRatioGrade("Terrible");
      setCurrentRatioComparisonDetails(
        getComparisonDetails(
          CURRENT_RATIO,
          LOW,
          financialVals,
          {
            currentRatioInusVal: avgVals?.currentRatio,
            arTurnoverRate: parseInt(avgVals?.accountsReceivableTurnoverRate),
          },
          company.currency
        )
      );
    } else if (
      dummyCurrentRatio > currentRationThirtyThreePercentHigh ||
      grade === HIGH
    ) {
      setCurrentRatioGrade("Good");
      setCurrentRatioComparisonDetails(
        getComparisonDetails(CURRENT_RATIO, HIGH, financialVals, {})
      );
    }
  };

  const handleQuickRationGrade = (
    dummyQuickRatio,
    quickRatioTenPercentLess,
    quickRationThirtyThreePercentHigh,
    financialVals,
    grade
  ) => {
    if (
      (dummyQuickRatio > quickRatioTenPercentLess &&
        dummyQuickRatio < quickRationThirtyThreePercentHigh) ||
      grade === AVERAGE
    ) {
      setQuickRatioGrade("Fair");
      setQuickRatioComparisonDetails(
        getComparisonDetails(QUICK_RATIO, AVERAGE, financialVals, {
          quickRatioInusVal: avgVals?.quickRatio,
          arTurnoverRate: parseInt(avgVals?.accountsReceivableTurnoverRate),
        })
      );
    } else if (dummyQuickRatio < quickRatioTenPercentLess || grade === LOW) {
      setQuickRatioGrade("Terrible");
      setQuickRatioComparisonDetails(
        getComparisonDetails(
          QUICK_RATIO,
          LOW,
          financialVals,
          {
            quickRatioInusVal: avgVals?.quickRatio,
            arTurnoverRate: parseInt(avgVals?.accountsReceivableTurnoverRate),
          },
          company.currency
        )
      );
    } else if (
      dummyQuickRatio > quickRationThirtyThreePercentHigh ||
      grade === HIGH
    ) {
      setQuickRatioGrade("Good");
      setQuickRatioComparisonDetails(
        getComparisonDetails(
          QUICK_RATIO,
          HIGH,
          financialVals,
          {
            quickRatioInusVal: avgVals?.quickRatio,
            arTurnoverRate: parseInt(avgVals?.accountsReceivableTurnoverRate),
          },
          company.currency
        )
      );
    }
  };

  const handleDebtEquityRatioGrade = (
    dummyDebtEquityRatio,
    debtEquityRatioTwentyFivePercentLow,
    debtEquityRatioTwentyFivePercentHigh,
    financialVals,
    grade
  ) => {
    if (
      (dummyDebtEquityRatio > debtEquityRatioTwentyFivePercentLow &&
        dummyDebtEquityRatio < debtEquityRatioTwentyFivePercentHigh) ||
      grade === AVERAGE
    ) {
      setDebtEquityRatioGrade("Fair");
      setDebtEquityRatioComparisonDetails(
        getComparisonDetails(
          DEDT_EQUITY_RATIO,
          AVERAGE,
          financialVals,
          {
            debtEquityRatioIndusVal: avgVals?.debtToEquityRatio,
          },
          company.currency
        )
      );
    } else if (
      dummyDebtEquityRatio < debtEquityRatioTwentyFivePercentLow ||
      grade === HIGH
    ) {
      setDebtEquityRatioGrade("Good");
      setDebtEquityRatioComparisonDetails(
        getComparisonDetails(
          DEDT_EQUITY_RATIO,
          HIGH,
          financialVals,
          {
            debtEquityRatioIndusVal: avgVals?.debtToEquityRatio,
          },
          company.currency
        )
      );
    } else if (
      dummyDebtEquityRatio > debtEquityRatioTwentyFivePercentHigh ||
      grade === LOW
    ) {
      setDebtEquityRatioGrade("Terrible");
      setDebtEquityRatioComparisonDetails(
        getComparisonDetails(
          DEDT_EQUITY_RATIO,
          LOW,
          financialVals,
          {
            debtEquityRatioIndusVal: avgVals?.debtToEquityRatio,
          },
          company.currency
        )
      );
    }
  };

  const handleInventoryTurnoverGrade = (
    dummyInventoryTurnover,
    inventoryTurnoverFifteenPercenLow,
    inventoryTurnoverFifteenPercenHigh,
    financialVals,
    grade
  ) => {
    if (
      (dummyInventoryTurnover > inventoryTurnoverFifteenPercenLow &&
        dummyInventoryTurnover < inventoryTurnoverFifteenPercenHigh) ||
      grade === AVERAGE
    ) {
      setInventoryTurnoverGrade("Fair");
      setInventoryTurnoverComparisonDetails(
        getComparisonDetails(
          INVENTORY_TURNOVER,
          AVERAGE,
          financialVals,
          {
            debtEquityRatioIndusVal: avgVals?.inventoryTurnoverRate
              ? avgVals?.inventoryTurnoverRate
              : 0,
          },
          company.currency
        )
      );
    } else if (
      dummyInventoryTurnover < inventoryTurnoverFifteenPercenLow ||
      grade === LOW
    ) {
      setInventoryTurnoverGrade("Good");
      setInventoryTurnoverComparisonDetails(
        getComparisonDetails(
          INVENTORY_TURNOVER,
          LOW,
          financialVals,
          {
            debtEquityRatioIndusVal: avgVals?.inventoryTurnoverRate
              ? avgVals?.inventoryTurnoverRate
              : 0,
          },
          company.currency
        )
      );
    } else if (
      dummyInventoryTurnover > inventoryTurnoverFifteenPercenHigh ||
      grade === HIGH
    ) {
      setInventoryTurnoverGrade("Terrible");
      setInventoryTurnoverComparisonDetails(
        getComparisonDetails(
          INVENTORY_TURNOVER,
          HIGH,
          financialVals,
          {
            debtEquityRatioIndusVal: avgVals?.inventoryTurnoverRate
              ? avgVals?.inventoryTurnoverRate
              : 0,
          },
          company.currency
        )
      );
    }
  };

  const handleArTurnoverGrade = (
    dummyArTurnover,
    arTurnoverFifteenPercenLow,
    arTurnoverFifteenPercenHigh,
    financialVals,
    grade
  ) => {
    if (
      (dummyArTurnover > arTurnoverFifteenPercenLow &&
        dummyArTurnover < arTurnoverFifteenPercenHigh) ||
      grade === AVERAGE
    ) {
      setArTurnoverGrade("Fair");
      setArTurnoverComparisonDetails(
        getComparisonDetails(
          AR_TURNOVER,
          AVERAGE,
          financialVals,
          {
            arTurnoverIndusVal: parseInt(
              avgVals?.accountsReceivableTurnoverRate
            ),
          },
          company.currency
        )
      );
    } else if (dummyArTurnover < arTurnoverFifteenPercenLow || grade === HIGH) {
      setArTurnoverGrade("Good");
      setArTurnoverComparisonDetails(
        getComparisonDetails(
          AR_TURNOVER,
          HIGH,
          financialVals,
          {
            arTurnoverIndusVal: parseInt(
              avgVals?.accountsReceivableTurnoverRate
            ),
          },
          company.currency
        )
      );
    } else if (dummyArTurnover > arTurnoverFifteenPercenHigh || grade === LOW) {
      setArTurnoverGrade("Terrible");
      setArTurnoverComparisonDetails(
        getComparisonDetails(
          AR_TURNOVER,
          LOW,
          financialVals,
          {
            arTurnoverIndusVal: parseInt(
              avgVals?.accountsReceivableTurnoverRate
            ),
          },
          company.currency
        )
      );
    }
  };

  const handleGrossMarginGrade = (
    dummyGrossMargin,
    grossMarginFivePercentLow,
    grossMarginTenPercentHigh,
    financialVals,
    grade
  ) => {
    if (
      (dummyGrossMargin > grossMarginFivePercentLow &&
        dummyGrossMargin < grossMarginTenPercentHigh) ||
      grade === AVERAGE
    ) {
      setGrossMarginGrade("Fair");
      setGrossMarginComparisonDetails(
        getComparisonDetails(
          GROSS_MARGIN,
          AVERAGE,
          financialVals,
          {
            grossMarginIndusVal: parseInt(avgVals?.grossProfitePercentage),
          },
          company.currency
        )
      );
    } else if (dummyGrossMargin < grossMarginFivePercentLow || grade === LOW) {
      setGrossMarginGrade("Terrible");
      setGrossMarginComparisonDetails(
        getComparisonDetails(
          GROSS_MARGIN,
          LOW,
          financialVals,
          {
            grossMarginIndusVal: parseInt(avgVals?.grossProfitePercentage),
          },
          company.currency
        )
      );
    } else if (dummyGrossMargin > grossMarginTenPercentHigh || grade === HIGH) {
      setGrossMarginGrade("Good");
      setGrossMarginComparisonDetails(
        getComparisonDetails(
          GROSS_MARGIN,
          HIGH,
          {
            grossMarginIndusVal: parseInt(avgVals?.grossProfitePercentage),
          },
          company.currency
        )
      );
    }
  };

  const handleNetMarginGrade = (
    dummyNetMargin,
    netMarginTenPercentLow,
    netMarginTenPercentHigh,
    financialVals,
    grade
  ) => {
    if (
      (dummyNetMargin > netMarginTenPercentLow &&
        dummyNetMargin < netMarginTenPercentHigh) ||
      grade === AVERAGE
    ) {
      setNetMarginGrade("Fair");
      setNetMarginComparisonDetails(
        getComparisonDetails(
          NET_MARGIN,
          AVERAGE,
          financialVals,
          {
            netMarginIndusVal: avgVals?.netProfitePercentage,
          },
          company.currency
        )
      );
    } else if (dummyNetMargin < netMarginTenPercentLow || grade === LOW) {
      setNetMarginGrade("Terrible");
      setNetMarginComparisonDetails(
        getComparisonDetails(
          NET_MARGIN,
          LOW,
          financialVals,
          {
            netMarginIndusVal: avgVals?.netProfitePercentage,
          },
          company.currency
        )
      );
    } else if (dummyNetMargin > netMarginTenPercentHigh || grade === HIGH) {
      setNetMarginGrade("Good");
      setNetMarginComparisonDetails(
        getComparisonDetails(
          NET_MARGIN,
          HIGH,
          financialVals,
          {
            netMarginIndusVal: avgVals?.netProfitePercentage,
          },
          company.currency
        )
      );
    }
  };

  const handleSalesToAssetsGrade = (
    dummySalesToAssets,
    salesToAssetsTenPercentLow,
    salesToAssetsTwentyFivePercentHigh,
    financialVals,
    grade
  ) => {
    if (
      (dummySalesToAssets > salesToAssetsTenPercentLow &&
        dummySalesToAssets < salesToAssetsTwentyFivePercentHigh) ||
      grade === AVERAGE
    ) {
      setSalesToAssetsGrade("Fair");
      setSalesToAssetsComparisonDetails(
        getComparisonDetails(
          SALES_TO_ASSETS,
          AVERAGE,
          financialVals,
          {
            salesToAssetsIndusVal: avgVals?.salesToAssets,
          },
          company.currency
        )
      );
    } else if (
      dummySalesToAssets < salesToAssetsTenPercentLow ||
      grade === LOW
    ) {
      setSalesToAssetsGrade("Terrible");
      setSalesToAssetsComparisonDetails(
        getComparisonDetails(
          SALES_TO_ASSETS,
          LOW,
          financialVals,
          {
            salesToAssetsIndusVal: avgVals?.salesToAssets,
          },
          company.currency
        )
      );
    } else if (
      dummySalesToAssets > salesToAssetsTwentyFivePercentHigh ||
      grade === HIGH
    ) {
      setSalesToAssetsGrade("Good");
      setSalesToAssetsComparisonDetails(
        getComparisonDetails(
          SALES_TO_ASSETS,
          HIGH,
          financialVals,
          {
            salesToAssetsIndusVal: avgVals?.salesToAssets,
          },
          company.currency
        )
      );
    }
  };

  const handleEbidtaGrade = (
    dummyEbidta,
    ebidtaTenPercentLow,
    ebidtaTwentyFivePercentHigh,
    financialVals,
    grade
  ) => {
    if (
      (dummyEbidta > ebidtaTenPercentLow &&
        dummyEbidta < ebidtaTwentyFivePercentHigh) ||
      grade === AVERAGE
    ) {
      setEbidtaGrade("Fair");
      setEbidtaComparisonDetails(
        getComparisonDetails(
          EBITDA,
          AVERAGE,
          financialVals,
          {
            ebidtaIndusVal: avgVals?.ebidta,
            salesToAssetsIndusVal: avgVals?.salesToAssets,
            grossProfitIndusVal: parseInt(avgVals?.grossProfitePercentage),
            operatingExpenseIndusVal: indusAvgs["Operating Expense"],
          },
          company.currency
        )
      );
    } else if (dummyEbidta < ebidtaTenPercentLow || grade === LOW) {
      setEbidtaGrade("Terrible");
      setEbidtaComparisonDetails(
        getComparisonDetails(
          EBITDA,
          LOW,
          financialVals,
          {
            ebidtaIndusVal: avgVals?.ebidta,
            salesToAssetsIndusVal: avgVals?.salesToAssets,
            grossProfitIndusVal: parseInt(avgVals?.grossProfitePercentage),
            operatingExpenseIndusVal: indusAvgs["Operating Expense"],
          },
          company.currency
        )
      );
    } else if (dummyEbidta > ebidtaTwentyFivePercentHigh || grade === HIGH) {
      setEbidtaGrade("Good");
      setEbidtaComparisonDetails(
        getComparisonDetails(
          EBITDA,
          HIGH,
          financialVals,
          {
            ebidtaIndusVal: avgVals?.ebidta,
            salesToAssetsIndusVal: avgVals?.salesToAssets,
            grossProfitIndusVal: parseInt(avgVals?.grossProfitePercentage),
            operatingExpenseIndusVal: indusAvgs["Operating Expense"],
          },
          company.currency
        )
      );
    }
  };

  const handleROAGrade = (
    dummyRoa,
    roaTenPercentLow,
    roaTwentyFivePercentHigh,
    financialVals,
    grade
  ) => {
    if (
      (dummyRoa > roaTenPercentLow && dummyRoa < roaTwentyFivePercentHigh) ||
      grade === AVERAGE
    ) {
      setRoaGrade("Fair");
      setRoaComparisonDetails(
        getComparisonDetails(
          ROA,
          AVERAGE,
          financialVals,
          {
            ebidtaIndusVal: avgVals?.roa,
          },
          company.currency
        )
      );
    } else if (dummyRoa < roaTenPercentLow || grade === LOW) {
      setRoaGrade("Terrible");
      setRoaComparisonDetails(
        getComparisonDetails(
          ROA,
          LOW,
          financialVals,
          {
            ebidtaIndusVal: avgVals?.roa,
          },
          company.currency
        )
      );
    } else if (dummyRoa > roaTwentyFivePercentHigh || grade === HIGH) {
      setRoaGrade("Good");
      setRoaComparisonDetails(
        getComparisonDetails(
          ROA,
          HIGH,
          financialVals,
          {
            ebidtaIndusVal: avgVals?.roa,
          },
          company.currency
        )
      );
    }
  };

  const handleROIGrade = (
    dummyRoi,
    roiTenPercentLow,
    roiTwentyFivePercentHigh,
    financialVals,
    grade
  ) => {
    if (
      (dummyRoi > roiTenPercentLow && dummyRoi < roiTwentyFivePercentHigh) ||
      grade === AVERAGE
    ) {
      setRoiGrade("Fair");
      setRoiComparisonDetails(
        getComparisonDetails(
          ROI,
          AVERAGE,
          financialVals,
          {
            ebidtaIndusVal: avgVals?.roi,
          },
          company.currency
        )
      );
    } else if (dummyRoi < roiTenPercentLow || grade === LOW) {
      setRoiGrade("Terrible");
      setRoiComparisonDetails(
        getComparisonDetails(
          ROI,
          LOW,
          financialVals,
          {
            ebidtaIndusVal: avgVals?.roi,
          },
          company.currency
        )
      );
    } else if (dummyRoi > roiTwentyFivePercentHigh || grade === LOW) {
      setRoiGrade("Good");
      setRoiComparisonDetails(
        getComparisonDetails(
          ROI,
          HIGH,
          financialVals,
          {
            ebidtaIndusVal: avgVals?.roi,
          },
          company.currency
        )
      );
    }
  };

  const handleApTurnoverGrade = (
    dummyApTurnover,
    apTurnoverFifteenPerLow,
    apTurnoverFifteenPerHigh,
    financialVals,
    grade
  ) => {
    if (
      (dummyApTurnover > apTurnoverFifteenPerLow &&
        dummyApTurnover < apTurnoverFifteenPerHigh) ||
      grade === AVERAGE
    ) {
      setApTurnoverGrade("Fair");
      setApTurnoverComparisonDetails(
        getComparisonDetails(
          AP_TURNOVER,
          AVERAGE,
          financialVals,
          {
            apTurnoverIndusVal: avgVals?.accountsPayableTurnoverRate
              ? avgVals?.accountsPayableTurnoverRate
              : 0,
          },
          company.currency
        )
      );
    } else if (dummyApTurnover < apTurnoverFifteenPerLow || grade === LOW) {
      setApTurnoverGrade("Terrible");
      setApTurnoverComparisonDetails(
        getComparisonDetails(
          AP_TURNOVER,
          LOW,
          financialVals,
          {
            apTurnoverIndusVal: avgVals?.accountsPayableTurnoverRate
              ? avgVals?.accountsPayableTurnoverRate
              : 0,
          },
          company.currency
        )
      );
    } else if (dummyApTurnover > apTurnoverFifteenPerHigh || grade === HIGH) {
      setApTurnoverGrade("Good");
      setApTurnoverComparisonDetails(
        getComparisonDetails(
          AP_TURNOVER,
          HIGH,
          financialVals,
          {
            apTurnoverIndusVal: avgVals?.accountsPayableTurnoverRate
              ? avgVals?.accountsPayableTurnoverRate
              : 0,
          },
          company.currency
        )
      );
    }
  };

  const handleDscrGrade = (
    dummyDscr,
    lowRange,
    highRange,
    financialVals,
    grade
  ) => {
    if ((dummyDscr > lowRange && dummyDscr < highRange) || grade === AVERAGE) {
      setDscrGrade("Fair");
      setDscrComparisonDetails(
        getComparisonDetails(DSCR, AVERAGE, financialVals, {}, company.currency)
      );
    } else if (dummyDscr < lowRange || grade === LOW) {
      setDscrGrade("Terrible");
      setDscrComparisonDetails(
        getComparisonDetails(DSCR, LOW, financialVals, {}, company.currenc)
      );
    } else if (dummyDscr > highRange || grade === HIGH) {
      setDscrGrade("Good");
      setDscrComparisonDetails(
        getComparisonDetails(DSCR, HIGH, financialVals, {}, company.currency)
      );
    }
  };

  const handleDcohGrade = (
    dummyDcoh,
    lowRange,
    highRange,
    financialVals,
    grade
  ) => {
    if ((dummyDcoh > lowRange && dummyDcoh < highRange) || grade === AVERAGE) {
      setDcohGrade("Fair");
      setDcohComparisonDetails(
        getComparisonDetails(DCOH, AVERAGE, financialVals, {}, company.currency)
      );
    } else if (dummyDcoh < lowRange || grade === LOW) {
      setDcohGrade("Terrible");
      setDcohComparisonDetails(
        getComparisonDetails(DCOH, LOW, financialVals, {}, company.currency)
      );
    } else if (dummyDcoh > highRange || grade === HIGH) {
      setDcohGrade("Good");
      setDcohComparisonDetails(
        getComparisonDetails(DCOH, HIGH, financialVals, {}, company.currency)
      );
    }
  };

  const handleSelectDateType = async (e) => {
    setDateLoading(true);

    const typeDate = e.target.value;

    const payload = {
      companyId: company._id,
      dateType: typeDate,
    };

    const historicalValResp = await dispatch(fetchHistoricalVals(payload));

    setHistoricalValues(historicalValResp.historicalVals);

    const res = await dispatch(fetchYears(payload));

    setDateType(typeDate);
    setDatePeriods(res);
    setDateLoading(false);
  };

  const handlePeriodSelection = (e) => {
    const selectedPeriod = e.target.value;

    const financialVals = financialData.find((fd) => fd._id === selectedPeriod);
    setSelectFinancialVals(financialVals);

    const payload = {
      date: financialVals.year,
      page: "FinancialDoctor",
      companyId: company._id,
    };

    dispatch(fetchFinancialDoctorNotes(payload));

    let errors = {};

    const dummyCurrentRatio =
      financialVals.balanceSheet.currenAssets /
      financialVals.balanceSheet.currenLiabilities;

    if (isNaN(dummyCurrentRatio)) errors = { ...errors, currentRatio: true };

    const dummyQuickRatio =
      (financialVals?.balanceSheet?.cash +
        financialVals.balanceSheet.accountReceivableinUSD) /
      financialVals.balanceSheet.currenLiabilities;

    if (isNaN(dummyQuickRatio)) errors = { ...errors, quickRatio: true };

    const dummyDebtEquityRatio =
      financialVals?.balanceSheet?.totalLiabilities /
      financialVals?.balanceSheet?.totalEquity;

    if (isNaN(dummyDebtEquityRatio)) errors = { ...errors, equityRatio: true };

    const dummyInventoryTurnover = (
      365 /
      (financialVals.incomeStatement.costOfGoodsSoldinUSD /
        financialVals.balanceSheet.inventoryinUSD)
    )?.toFixed(0);

    if (isNaN(dummyInventoryTurnover))
      errors = { ...errors, inventoryTurnoverRate: true };

    const dummyArTurnover = (
      365 /
      (financialVals.incomeStatement.salesinUSD /
        financialVals.balanceSheet.accountReceivableinUSD)
    )?.toFixed(0);

    if (isNaN(dummyArTurnover)) errors = { ...errors, arTurnoverRate: true };

    const dummyGrossMargin =
      (financialVals.incomeStatement.grossProfiteinUSD /
        financialVals.incomeStatement.salesinUSD) *
      100;

    if (isNaN(dummyGrossMargin)) errors = { ...errors, grossMargin: true };

    const dummyNetMargin =
      (parseInt(financialVals.incomeStatement.netProfiteBeforeTaxinUSD) /
        financialVals.incomeStatement.salesinUSD) *
      100;

    if (isNaN(dummyNetMargin)) errors = { ...errors, netMargin: true };

    const dummySalesToAssets =
      financialVals.incomeStatement.salesinUSD /
      financialVals.balanceSheet.totalAssetsinUSD;

    if (isNaN(dummySalesToAssets)) errors = { ...errors, salesToAssets: true };

    const dummyEbidta =
      ((parseInt(financialVals.incomeStatement.netProfiteBeforeTaxinUSD) +
        parseInt(financialVals.incomeStatement.interestExpense) +
        parseInt(financialVals.incomeStatement.taxesPaid) +
        parseInt(financialVals.incomeStatement.depExpense) +
        parseInt(financialVals.incomeStatement.amortExpense)) /
        financialVals.incomeStatement.salesinUSD) *
      100;

    if (isNaN(dummyEbidta)) errors = { ...errors, ebidta: true };

    const dummyRoa =
      (parseInt(financialVals.incomeStatement.netProfiteBeforeTaxinUSD) /
        financialVals.balanceSheet.totalAssetsinUSD) *
      100;

    if (isNaN(dummyRoa)) errors = { ...errors, roa: true };

    const dummyRoi =
      (parseInt(financialVals.incomeStatement.netProfiteBeforeTaxinUSD) /
        financialVals.balanceSheet.totalEquity) *
      100;

    if (isNaN(dummyRoi)) errors = { ...errors, roi: true };

    const dummyApTurnover = (
      365 /
      (financialVals.incomeStatement.costOfGoodsSoldinUSD /
        financialVals.balanceSheet.accountPayableinUSD)
    )?.toFixed(0);

    if (isNaN(dummyApTurnover)) errors = { ...errors, apTurnoverRate: true };

    const dummyDscr = financialVals.balanceSheet.debtPayments
      ? (parseInt(financialVals.incomeStatement.netProfiteBeforeTaxinUSD) +
          parseInt(financialVals.incomeStatement.interestExpense) +
          parseInt(financialVals.incomeStatement.taxesPaid) +
          parseInt(financialVals.incomeStatement.depExpense) +
          parseInt(financialVals.incomeStatement.amortExpense)) /
        financialVals.balanceSheet.debtPayments
      : 0;
    if (isNaN(dummyDscr)) errors = { ...errors, dscr: true };

    const dummyDcoh =
      financialVals.balanceSheet.cash /
      ((financialVals.incomeStatement.operatingExpense -
        financialVals.incomeStatement.depExpense -
        financialVals.incomeStatement.amortExpense) /
        365);

    if (isNaN(dummyDcoh)) errors = { ...errors, dcoh: true };

    setMissingValError(errors);

    setCurrentRatio(dummyCurrentRatio?.toFixed(2));
    console.log("dummyQuickRatio", dummyQuickRatio);
    setQuickRatio(dummyQuickRatio?.toFixed(2));
    setDebtEquityRatio(dummyDebtEquityRatio?.toFixed(2));
    setInventoryTurnover(dummyInventoryTurnover);
    setArTurnover(dummyArTurnover);
    setGrossMargin(dummyGrossMargin?.toFixed(2));
    setNetMargin(dummyNetMargin?.toFixed(2));
    setSalesToAssets(dummySalesToAssets?.toFixed(2));
    setEbidta(dummyEbidta?.toFixed(2));
    setRoa(dummyRoa?.toFixed(2));
    setRoi(dummyRoi?.toFixed(2));
    setApTurnover(dummyApTurnover);

    setDscr(dummyDscr?.toFixed(2));
    setDcoh(dummyDcoh?.toFixed(2));

    const currentRatioTenPercentLess =
      avgVals?.currentRatio - avgVals?.currentRatio * 0.1;
    const currentRationThirtyThreePercentHigh =
      avgVals?.currentRatio + avgVals?.currentRatio * 0.33;

    const quickRatioTenPercentLess =
      avgVals?.quickRatio - avgVals?.quickRatio * 0.1;
    const quickRationThirtyThreePercentHigh =
      avgVals?.quickRatio + avgVals?.quickRatio * 0.33;

    const debtEquityRatioTwentyFivePercentLow =
      avgVals?.debtToEquityRatio - avgVals?.debtToEquityRatio * 0.25;
    const debtEquityRatioTwentyFivePercentHigh =
      avgVals?.debtToEquityRatio + avgVals?.debtToEquityRatio * 0.25;

    const inventoryTurnoverRate = avgVals?.inventoryTurnoverRate
      ? (365 / avgVals?.inventoryTurnoverRate)?.toFixed(0)
      : 0;

    const inventoryTurnoverFifteenPercenLow =
      inventoryTurnoverRate - inventoryTurnoverRate * 0.15;
    const inventoryTurnoverFifteenPercenHigh =
      inventoryTurnoverRate + inventoryTurnoverRate * 0.15;

    const arTurnoverRate = avgVals?.accountsReceivableTurnoverRate
      ? (365 / avgVals?.accountsReceivableTurnoverRate)?.toFixed(0)
      : 0;

    const arTurnoverFifteenPercenLow = arTurnoverRate - arTurnoverRate * 0.15;
    const arTurnoverFifteenPercenHigh = arTurnoverRate + arTurnoverRate * 0.15;

    const grossMarginFivePercentLow =
      parseInt(avgVals?.grossProfitePercentage) -
      parseInt(avgVals?.grossProfitePercentage) * 0.5;
    const grossMarginTenPercentHigh =
      parseInt(avgVals?.grossProfitePercentage) +
      parseInt(avgVals?.grossProfitePercentage) * 0.1;

    const netMarginTenPercentHigh =
      avgVals?.netProfitePercentage + avgVals?.netProfitePercentage * 0.1;
    const netMarginTenPercentLow =
      avgVals?.netProfitePercentage - avgVals?.netProfitePercentage * 0.1;

    const salesToAssetsTenPercentLow =
      avgVals?.salesToAssets + avgVals?.salesToAssets * 0.1;
    const salesToAssetsTwentyFivePercentHigh =
      avgVals?.salesToAssets + avgVals?.salesToAssets * 0.25;

    const ebidtaTenPercentLow = avgVals?.ebidta - avgVals?.ebidta * 0.1;
    const ebidtaTwentyFivePercentHigh =
      avgVals?.ebidta + avgVals?.ebidta * 0.25;

    const roaTenPercentLow = avgVals?.roa - avgVals?.roa * 0.1;
    const roaTwentyFivePercentHigh = avgVals?.roa + avgVals?.roa * 0.25;

    const roiTenPercentLow = avgVals?.roi - avgVals?.roi * 0.1;
    const roiTwentyFivePercentHigh = avgVals?.roi + avgVals?.roi * 0.25;

    const apTurnoverRate = avgVals?.accountsPayableTurnoverRate
      ? parseInt((365 / avgVals?.accountsPayableTurnoverRate)?.toFixed(0))
      : 0;

    const apTurnoverFifteenPerHigh = apTurnoverRate + apTurnoverRate * 0.15;
    const apTurnoverFifteenPerLow = apTurnoverRate - apTurnoverRate * 0.15;

    handleCurrentRatioGrade(
      dummyCurrentRatio,
      currentRatioTenPercentLess,
      currentRationThirtyThreePercentHigh,
      financialVals
    );

    handleQuickRationGrade(
      dummyQuickRatio,
      quickRatioTenPercentLess,
      quickRationThirtyThreePercentHigh,
      financialVals
    );

    handleDebtEquityRatioGrade(
      dummyDebtEquityRatio,
      debtEquityRatioTwentyFivePercentLow,
      debtEquityRatioTwentyFivePercentHigh,
      financialVals
    );

    handleInventoryTurnoverGrade(
      dummyInventoryTurnover,
      inventoryTurnoverFifteenPercenLow,
      inventoryTurnoverFifteenPercenHigh,
      financialVals
    );

    handleArTurnoverGrade(
      dummyArTurnover,
      arTurnoverFifteenPercenLow,
      arTurnoverFifteenPercenHigh,
      financialVals
    );

    handleGrossMarginGrade(
      dummyGrossMargin,
      grossMarginFivePercentLow,
      grossMarginTenPercentHigh,
      financialVals
    );

    handleNetMarginGrade(
      dummyNetMargin,
      netMarginTenPercentLow,
      netMarginTenPercentHigh,
      financialVals
    );

    handleSalesToAssetsGrade(
      dummySalesToAssets,
      salesToAssetsTenPercentLow,
      salesToAssetsTwentyFivePercentHigh,
      financialVals
    );

    handleEbidtaGrade(
      dummyEbidta,
      ebidtaTenPercentLow,
      ebidtaTwentyFivePercentHigh,
      financialVals
    );

    handleROAGrade(
      dummyRoa,
      roaTenPercentLow,
      roaTwentyFivePercentHigh,
      financialVals
    );

    handleROIGrade(
      dummyRoi,
      roiTenPercentLow,
      roiTwentyFivePercentHigh,
      financialVals
    );

    handleApTurnoverGrade(
      dummyApTurnover,
      parseInt(apTurnoverFifteenPerLow),
      parseInt(apTurnoverFifteenPerHigh),
      financialVals
    );

    handleDcohGrade(dummyDcoh, 15, 45, financialVals);
    handleDscrGrade(dummyDscr, 1.06, 1.44, financialVals);
  };

  const gradeOptions = [
    { label: "Good", value: HIGH },
    { label: "Fair", value: AVERAGE },
    { label: "Terrible", value: LOW },
  ];

  const getColor = (grade) => {
    switch (grade) {
      case "Fair":
        return "#00BC8B";
      case "Terrible":
        return "#FF5252";
      case "Good":
        return "#00BDFF";
      default:
        return "";
    }
  };

  const handleGradeChange = (value, calculation) => {
    switch (calculation) {
      case CURRENT_RATIO:
        handleCurrentRatioGrade(
          currentRatio,
          null,
          null,
          selectedFinancialVals,
          value
        );
        break;

      case DEDT_EQUITY_RATIO:
        handleDebtEquityRatioGrade(
          debtEquityRatio,
          null,
          null,
          selectedFinancialVals,
          value
        );
        break;

      case QUICK_RATIO:
        handleQuickRationGrade(
          quickRatio,
          null,
          null,
          selectedFinancialVals,
          value
        );
        break;

      case INVENTORY_TURNOVER:
        handleInventoryTurnoverGrade(
          inventoryTurnover,
          null,
          null,
          selectedFinancialVals,
          value
        );

        break;

      case AR_TURNOVER:
        handleArTurnoverGrade(
          arTurnover,
          null,
          null,
          selectedFinancialVals,
          value
        );
        break;

      case GROSS_MARGIN:
        handleGrossMarginGrade(
          grossMargin,
          null,
          null,
          selectedFinancialVals,
          value
        );
        break;

      case NET_MARGIN:
        handleNetMarginGrade(
          netMargin,
          null,
          null,
          selectedFinancialVals,
          value
        );
        break;

      case SALES_TO_ASSETS:
        handleSalesToAssetsGrade(
          salesToAssets,
          null,
          null,
          selectedFinancialVals,
          value
        );
        break;

      case EBITDA:
        handleEbidtaGrade(ebidta, null, null, selectedFinancialVals, value);
        break;

      case ROA:
        handleROAGrade(roa, null, null, selectedFinancialVals, value);
        break;

      case ROI:
        handleROIGrade(roi, null, null, selectedFinancialVals, value);
        break;

      case AP_TURNOVER:
        handleApTurnoverGrade(
          apTurnover,
          null,
          null,
          selectedFinancialVals,
          value
        );
        break;

      case DCOH:
        handleDcohGrade(dcoh, null, null, selectedFinancialVals, value);
        break;

      case DSCR:
        handleDscrGrade(dscr, null, null, selectedFinancialVals, value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    savePDF();
  }, [
    isGraphUpdate,
    selectedFinancialVals?._id,
    currentRatioComparisonDetails,
    arTurnoverComparisonDetails,
    dscrComparisonDetails,
    currentRatioGrade,
    quickRatioGrade,
    inventoryTurnoverGrade,
    arTurnoverGrade,
    grossMarginGrade,
    netMarginGrade,
    salesToAssetsGrade,
    ebidtaGrade,
    roaGrade,
    apTurnoverGrade,
    dcohGrade,
    dscrGrade,
  ]);

  useEffect(() => {
    if (shouldScroll) {
      handleAutoSroll(scrollTo);
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  const savePDF = () =>
    setTimeout(() => {
      saveFDPDF();
    }, 3000);

  const saveFDPDF = async () => {
    try {
      dispatch({
        type: "GRAPH_LOADING",
      });

      if (!currentRatioGuageRef.current) {
        dispatch({
          type: "STOP_GRAPH_LOADING",
        });
        return;
      }

      const calculationRank = {
        currentRatio: currentRatioGrade,
        quickRatio: quickRatioGrade,
        debtEquityRatio: debtEquityRatioGrade,
        inventoryTurnover: inventoryTurnoverGrade,
        arTurnover: arTurnoverGrade,
        grossMargin: grossMarginGrade,
        netMargin: netMarginGrade,
        salesToAssets: salesToAssetsGrade,
        ebidta: ebidtaGrade,
        dcoh: dcohGrade,
        dscr: dscrGrade,
        ROA: roaGrade,
        ROI: roiGrade,
        apTurnover: apTurnoverGrade,
      };

      dispatch({
        type: "SET_CALCULATION_RANK",
        payload: {
          calculationRank,
        },
      });

      // reports

      const currentRatioReportCanvas = await html2canvas(
        currentRatioReportRef.current
      );
      currentRatioReportCanvas.getContext("2d", { willReadFrequently: true });
      const currentRatioReport64Image =
        await currentRatioReportCanvas.toDataURL("image/png");

      const quickRatioReportCanvas = await html2canvas(
        quickRatioReportRef.current
      );
      quickRatioReportCanvas.getContext("2d", { willReadFrequently: true });
      const quickRatioReport64Image = await quickRatioReportCanvas.toDataURL(
        "image/png"
      );

      const debtEqRatioReportCanvas = await html2canvas(
        debtEqRatioReportRef.current
      );
      debtEqRatioReportCanvas.getContext("2d", { willReadFrequently: true });
      const debtEqRatioReport64Image = await debtEqRatioReportCanvas.toDataURL(
        "image/png"
      );

      const grossMarginReportCanvas = await html2canvas(
        grossMarginReportRef.current
      );
      grossMarginReportCanvas.getContext("2d", { willReadFrequently: true });
      const grossMarginReport64Image = await grossMarginReportCanvas.toDataURL(
        "image/png"
      );

      const netMarginReportCanvas = await html2canvas(
        netMarginReportRef.current
      );
      netMarginReportCanvas.getContext("2d", { willReadFrequently: true });
      const netMarginReport64Image = await netMarginReportCanvas.toDataURL(
        "image/png"
      );

      const saleAssetsReportCanvas = await html2canvas(
        saleAssetsReportRef.current
      );
      saleAssetsReportCanvas.getContext("2d", { willReadFrequently: true });
      const saleAssetsReport64Image = await saleAssetsReportCanvas.toDataURL(
        "image/png"
      );

      const ebidtaFDReportCanvas = await html2canvas(ebidtaFDReportRef.current);
      ebidtaFDReportCanvas.getContext("2d", { willReadFrequently: true });
      const ebidtaFDReport64Image = await ebidtaFDReportCanvas.toDataURL(
        "image/png"
      );

      const roaReportCanvas = await html2canvas(roaReportRef.current);
      roaReportCanvas.getContext("2d", { willReadFrequently: true });
      const roaReport64Image = await roaReportCanvas.toDataURL("image/png");

      const roiReportCanvas = await html2canvas(roiReportRef.current);
      roiReportCanvas.getContext("2d", { willReadFrequently: true });
      const roiReport64Image = await roiReportCanvas.toDataURL("image/png");

      const invTurnoverReportCanvas = await html2canvas(
        invTurnoverReportRef.current
      );
      invTurnoverReportCanvas.getContext("2d", { willReadFrequently: true });
      const invTurnoverReport64Image = await invTurnoverReportCanvas.toDataURL(
        "image/png"
      );

      const arTurnoverReportCanvas = await html2canvas(
        arTurnoverReportRef.current
      );
      arTurnoverReportCanvas.getContext("2d", { willReadFrequently: true });
      const arTurnoverReport64Image = await arTurnoverReportCanvas.toDataURL(
        "image/png"
      );

      const apTurnoverReportCanvas = await html2canvas(
        apTurnoverReportRef.current
      );
      apTurnoverReportCanvas.getContext("2d", { willReadFrequently: true });
      const apTurnoverReport64Image = await apTurnoverReportCanvas.toDataURL(
        "image/png"
      );

      const dcohReportCanvas = await html2canvas(dcohReportRef.current);
      dcohReportCanvas.getContext("2d", { willReadFrequently: true });
      const dcohReport64Image = await dcohReportCanvas.toDataURL("image/png");

      const dscrReportCanvas = await html2canvas(dscrReportRef.current);
      dscrReportCanvas.getContext("2d", {
        willReadFrequently: true,
      });
      const dscrReport64Image = await dscrReportCanvas.toDataURL("image/png");

      // notes

      const currentRatioNotesCanvas = await html2canvas(
        currentRatioNotesRef.current
      );
      currentRatioNotesCanvas.getContext("2d", { willReadFrequently: true });
      const currentRatioNotes64Image = await currentRatioNotesCanvas.toDataURL(
        "image/png"
      );

      const quickRatioNotesCanvas = await html2canvas(
        quickRatioNotesRef.current
      );
      quickRatioNotesCanvas.getContext("2d", { willReadFrequently: true });
      const quickRatioNotes64Image = await quickRatioNotesCanvas.toDataURL(
        "image/png"
      );

      const debtEqRatioNotesCanvas = await html2canvas(
        debtEqRatioNotesRef.current
      );
      debtEqRatioNotesCanvas.getContext("2d", { willReadFrequently: true });
      const debtEqRatioNotes64Image = await debtEqRatioNotesCanvas.toDataURL(
        "image/png"
      );

      const grossMarginNotesCanvas = await html2canvas(
        grossMarginNotesRef.current
      );
      grossMarginNotesCanvas.getContext("2d", { willReadFrequently: true });
      const grossMarginNotes64Image = await grossMarginNotesCanvas.toDataURL(
        "image/png"
      );

      const netMarginNotesCanvas = await html2canvas(netMarginNotesRef.current);
      netMarginNotesCanvas.getContext("2d", { willReadFrequently: true });
      const netMarginNotes64Image = await netMarginNotesCanvas.toDataURL(
        "image/png"
      );

      const saleAssetsNotesCanvas = await html2canvas(
        saleAssetsNotesRef.current
      );
      saleAssetsNotesCanvas.getContext("2d", { willReadFrequently: true });
      const saleAssetsNotes64Image = await saleAssetsNotesCanvas.toDataURL(
        "image/png"
      );

      const ebidtaFDNotesCanvas = await html2canvas(ebidtaFDNotesRef.current);
      ebidtaFDNotesCanvas.getContext("2d", { willReadFrequently: true });
      const ebidtaFDNotes64Image = await ebidtaFDNotesCanvas.toDataURL(
        "image/png"
      );

      const roaNotesCanvas = await html2canvas(roaNotesRef.current);
      roaNotesCanvas.getContext("2d", { willReadFrequently: true });
      const roaNotes64Image = await roaNotesCanvas.toDataURL("image/png");

      const roiNotesCanvas = await html2canvas(roiNotesRef.current);
      roiNotesCanvas.getContext("2d", { willReadFrequently: true });
      const roiNotes64Image = await roiNotesCanvas.toDataURL("image/png");

      const invTurnoverNotesCanvas = await html2canvas(
        invTurnoverNotesRef.current
      );
      invTurnoverNotesCanvas.getContext("2d", { willReadFrequently: true });
      const invTurnoverNotes64Image = await invTurnoverNotesCanvas.toDataURL(
        "image/png"
      );

      const arTurnoverNotesCanvas = await html2canvas(
        arTurnoverNotesRef.current
      );
      arTurnoverNotesCanvas.getContext("2d", { willReadFrequently: true });
      const arTurnoverNotes64Image = await arTurnoverNotesCanvas.toDataURL(
        "image/png"
      );

      const apTurnoverNotesCanvas = await html2canvas(
        apTurnoverNotesRef.current
      );
      apTurnoverNotesCanvas.getContext("2d", { willReadFrequently: true });
      const apTurnoverNotes64Image = await apTurnoverNotesCanvas.toDataURL(
        "image/png"
      );

      const dcohNotesCanvas = await html2canvas(dcohNotesRef.current);
      dcohNotesCanvas.getContext("2d", { willReadFrequently: true });
      const dcohNotes64Image = await dcohNotesCanvas.toDataURL("image/png");

      const dscrNotesCanvas = await html2canvas(dscrNotesRef.current);
      dscrNotesCanvas.getContext("2d", { willReadFrequently: true });
      const dscrNotes64Image = await dscrNotesCanvas.toDataURL("image/png");

      // guage implementation

      const currentRatioGuageCanvas = await html2canvas(
        currentRatioGuageRef.current
      );
      currentRatioGuageCanvas.getContext("2d", { willReadFrequently: true });
      const currentRatioGuage64Image = await currentRatioGuageCanvas.toDataURL(
        "image/png"
      );

      const quickRatioGuageCanvas = await html2canvas(
        quickRatioGuageRef.current
      );
      quickRatioGuageCanvas.getContext("2d", { willReadFrequently: true });
      const quickRatioGuage64Image = await quickRatioGuageCanvas.toDataURL(
        "image/png"
      );

      const debtEqRatioGuageCanvas = await html2canvas(
        debtEqRatioGuageRef.current
      );
      debtEqRatioGuageCanvas.getContext("2d", { willReadFrequently: true });
      const debtEqRatioGuage64Image = await debtEqRatioGuageCanvas.toDataURL(
        "image/png"
      );

      const grossMarginGuageCanvas = await html2canvas(
        grossMarginGuageRef.current
      );
      grossMarginGuageCanvas.getContext("2d", { willReadFrequently: true });
      const grossMarginGuage64Image = await grossMarginGuageCanvas.toDataURL(
        "image/png"
      );

      const netMarginGuageCanvas = await html2canvas(netMarginGuageRef.current);
      netMarginGuageCanvas.getContext("2d", { willReadFrequently: true });
      const netMarginGuage64Image = await netMarginGuageCanvas.toDataURL(
        "image/png"
      );

      const saleAssetsGuageCanvas = await html2canvas(
        saleAssetsGuageRef.current
      );
      saleAssetsGuageCanvas.getContext("2d", { willReadFrequently: true });
      const saleAssetsGuage64Image = await saleAssetsGuageCanvas.toDataURL(
        "image/png"
      );

      const ebidtaFDGuageCanvas = await html2canvas(ebidtaFDGuageRef.current);
      ebidtaFDGuageCanvas.getContext("2d", { willReadFrequently: true });
      const ebidtaFDGuage64Image = await ebidtaFDGuageCanvas.toDataURL(
        "image/png"
      );

      const roaGuageCanvas = await html2canvas(roaGuageRef.current);
      roaGuageCanvas.getContext("2d", { willReadFrequently: true });
      const roaGuage64Image = await roaGuageCanvas.toDataURL("image/png");

      const roiGuageCanvas = await html2canvas(roiGuageRef.current);
      roiGuageCanvas.getContext("2d", { willReadFrequently: true });
      const roiGuage64Image = await roiGuageCanvas.toDataURL("image/png");

      const invTurnoverGuageCanvas = await html2canvas(
        invTurnoverGuageRef.current
      );
      invTurnoverGuageCanvas.getContext("2d", { willReadFrequently: true });
      const invTurnoverGuage64Image = await invTurnoverGuageCanvas.toDataURL(
        "image/png"
      );

      const arTurnoverGuageCanvas = await html2canvas(
        arTurnoverGuageRef.current
      );
      arTurnoverGuageCanvas.getContext("2d", { willReadFrequently: true });
      const arTurnoverGuage64Image = await arTurnoverGuageCanvas.toDataURL(
        "image/png"
      );

      const apTurnoverGuageCanvas = await html2canvas(
        apTurnoverGuageRef.current
      );
      apTurnoverGuageCanvas.getContext("2d", { willReadFrequently: true });
      const apTurnoverGuage64Image = await apTurnoverGuageCanvas.toDataURL(
        "image/png"
      );

      const dcohGuageCanvas = await html2canvas(dcohGuageRef.current);
      dcohGuageCanvas.getContext("2d", { willReadFrequently: true });
      const dcohGuage64Image = await dcohGuageCanvas.toDataURL("image/png");

      const dscrGuageCanvas = await html2canvas(dscrGuageRef.current);
      dscrGuageCanvas.getContext("2d", {
        willReadFrequently: true,
      });
      const dscrGuage64Image = await dscrGuageCanvas.toDataURL("image/png");

      // combining gauge and report

      const currentRatioGuageAndReports = await combineImages(
        currentRatioGuageCanvas,
        currentRatioGuage64Image,
        currentRatioReportCanvas,
        currentRatioReport64Image
      );

      const quickRatioGuageAndReports = await combineImages(
        quickRatioGuageCanvas,
        quickRatioGuage64Image,
        quickRatioReportCanvas,
        quickRatioReport64Image
      );

      const debtEqRatioGuageAndReports = await combineImages(
        debtEqRatioGuageCanvas,
        debtEqRatioGuage64Image,
        debtEqRatioReportCanvas,
        debtEqRatioReport64Image
      );

      const grossMarginGuageAndReports = await combineImages(
        grossMarginGuageCanvas,
        grossMarginGuage64Image,
        grossMarginReportCanvas,
        grossMarginReport64Image
      );

      const netMarginGuageAndReports = await combineImages(
        netMarginGuageCanvas,
        netMarginGuage64Image,
        netMarginReportCanvas,
        netMarginReport64Image
      );

      const saleAssetsGuageAndReports = await combineImages(
        saleAssetsGuageCanvas,
        saleAssetsGuage64Image,
        saleAssetsReportCanvas,
        saleAssetsReport64Image
      );

      const ebidtaFDGuageAndReports = await combineImages(
        ebidtaFDGuageCanvas,
        ebidtaFDGuage64Image,
        ebidtaFDReportCanvas,
        ebidtaFDReport64Image
      );

      const roaGuageAndReports = await combineImages(
        roaGuageCanvas,
        roaGuage64Image,
        roaReportCanvas,
        roaReport64Image
      );

      const roiGuageAndReports = await combineImages(
        roiGuageCanvas,
        roiGuage64Image,
        roiReportCanvas,
        roiReport64Image
      );

      const invTurnoverGuageAndReports = await combineImages(
        invTurnoverGuageCanvas,
        invTurnoverGuage64Image,
        invTurnoverReportCanvas,
        invTurnoverReport64Image
      );

      const arTurnoverGuageAndReports = await combineImages(
        arTurnoverGuageCanvas,
        arTurnoverGuage64Image,
        arTurnoverReportCanvas,
        arTurnoverReport64Image
      );

      const apTurnoverGuageAndReports = await combineImages(
        apTurnoverGuageCanvas,
        apTurnoverGuage64Image,
        apTurnoverReportCanvas,
        apTurnoverReport64Image
      );

      const dcohGuageAndReports = await combineImages(
        dcohGuageCanvas,
        dcohGuage64Image,
        dcohReportCanvas,
        dcohReport64Image
      );

      const dscrGuageAndReports = await combineImages(
        dscrGuageCanvas,
        dscrGuage64Image,
        dscrReportCanvas,
        dscrReport64Image
      );

      //chart
      const currentRatioChartCanvas = await html2canvas(
        currentRatioChartRef.current
      );
      currentRatioChartCanvas.getContext("2d", { willReadFrequently: true });
      const currentRatioChart64Image = await currentRatioChartCanvas.toDataURL(
        "image/png"
      );

      const quickRatioChartCanvas = await html2canvas(
        quickRatioChartRef.current
      );
      quickRatioChartCanvas.getContext("2d", { willReadFrequently: true });
      const quickRatioChart64Image = await quickRatioChartCanvas.toDataURL(
        "image/png"
      );

      const debtEqRatioChartCanvas = await html2canvas(
        debtEqRatioChartRef.current
      );
      debtEqRatioChartCanvas.getContext("2d", { willReadFrequently: true });
      const debtEqRatioChart64Image = await debtEqRatioChartCanvas.toDataURL(
        "image/png"
      );

      const grossMarginChartCanvas = await html2canvas(
        grossMarginChartRef.current
      );
      grossMarginChartCanvas.getContext("2d", { willReadFrequently: true });
      const grossMarginChart64Image = await grossMarginChartCanvas.toDataURL(
        "image/png"
      );

      const netMarginChartCanvas = await html2canvas(netMarginChartRef.current);
      netMarginChartCanvas.getContext("2d", { willReadFrequently: true });
      const netMarginChart64Image = await netMarginChartCanvas.toDataURL(
        "image/png"
      );

      const saleAssetsChartCanvas = await html2canvas(
        saleAssetsChartRef.current
      );
      saleAssetsChartCanvas.getContext("2d", { willReadFrequently: true });
      const saleAssetsChart64Image = await saleAssetsChartCanvas.toDataURL(
        "image/png"
      );

      const ebidtaFDChartCanvas = await html2canvas(ebidtaFDChartRef.current);
      ebidtaFDChartCanvas.getContext("2d", { willReadFrequently: true });
      const ebidtaFDChart64Image = await ebidtaFDChartCanvas.toDataURL(
        "image/png"
      );

      const roaChartCanvas = await html2canvas(roaChartRef.current);
      roaChartCanvas.getContext("2d", { willReadFrequently: true });
      const roaChart64Image = await roaChartCanvas.toDataURL("image/png");

      const roiChartCanvas = await html2canvas(roiChartRef.current);
      roiChartCanvas.getContext("2d", { willReadFrequently: true });
      const roiChart64Image = await roiChartCanvas.toDataURL("image/png");

      const invTurnoverChartCanvas = await html2canvas(
        invTurnoverChartRef.current
      );
      invTurnoverChartCanvas.getContext("2d", { willReadFrequently: true });
      const invTurnoverChart64Image = await invTurnoverChartCanvas.toDataURL(
        "image/png"
      );

      const arTurnoverChartCanvas = await html2canvas(
        arTurnoverChartRef.current
      );
      arTurnoverChartCanvas.getContext("2d", { willReadFrequently: true });
      const arTurnoverChart64Image = await arTurnoverChartCanvas.toDataURL(
        "image/png"
      );

      const apTurnoverChartCanvas = await html2canvas(
        apTurnoverChartRef.current
      );
      apTurnoverChartCanvas.getContext("2d", { willReadFrequently: true });
      const apTurnoverChart64Image = await apTurnoverChartCanvas.toDataURL(
        "image/png"
      );

      const dcohChartCanvas = await html2canvas(dcohChartRef.current);
      dcohChartCanvas.getContext("2d", { willReadFrequently: true });
      const dcohChart64Image = await dcohChartCanvas.toDataURL("image/png");

      const dscrChartCanvas = await html2canvas(dscrChartRef.current);
      dscrChartCanvas.getContext("2d", { willReadFrequently: true });
      const dscrChart64Image = await dscrChartCanvas.toDataURL("image/png");

      const payload = {
        notes: {
          currentRatioChart: currentRatioNotes64Image,
          quickRatioChart: quickRatioNotes64Image,
          debtEqRatioChart: debtEqRatioNotes64Image,
          grossMarginChart: grossMarginNotes64Image,
          netMarginChart: netMarginNotes64Image,
          saleAssetsChart: saleAssetsNotes64Image,
          ebidtaFDChart: ebidtaFDNotes64Image,
          roaChart: roaNotes64Image,
          roiChart: roiNotes64Image,
          invTurnoverChart: invTurnoverNotes64Image,
          arTurnoverChart: arTurnoverNotes64Image,
          apTurnoverChart: apTurnoverNotes64Image,
          dcohChart: dcohNotes64Image,
          dscrChart: dscrNotes64Image,
        },
        images: {
          currentRatioGuage: currentRatioGuageAndReports,
          currentRatioChart: currentRatioChart64Image,

          quickRatioGuage: quickRatioGuageAndReports,
          quickRatioChart: quickRatioChart64Image,

          debtEqRatioGuage: debtEqRatioGuageAndReports,
          debtEqRatioChart: debtEqRatioChart64Image,

          grossMarginGuage: grossMarginGuageAndReports,
          grossMarginChart: grossMarginChart64Image,

          netMarginGuage: netMarginGuageAndReports,
          netMarginChart: netMarginChart64Image,

          saleAssetsGuage: saleAssetsGuageAndReports,
          saleAssetsChart: saleAssetsChart64Image,

          ebidtaFDGuage: ebidtaFDGuageAndReports,
          ebidtaFDChart: ebidtaFDChart64Image,

          roaGuage: roaGuageAndReports,
          roaChart: roaChart64Image,

          roiGuage: roiGuageAndReports,
          roiChart: roiChart64Image,

          invTurnoverGuage: invTurnoverGuageAndReports,
          invTurnoverChart: invTurnoverChart64Image,

          arTurnoverGuage: arTurnoverGuageAndReports,
          arTurnoverChart: arTurnoverChart64Image,

          apTurnoverGuage: apTurnoverGuageAndReports,
          apTurnoverChart: apTurnoverChart64Image,

          dcohGuage: dcohGuageAndReports,
          dcohChart: dcohChart64Image,

          dscrGuage: dscrGuageAndReports,
          dscrChart: dscrChart64Image,
        },
      };

      dispatch(updatePDFData(payload));
      dispatch({
        type: "STOP_GRAPH_LOADING",
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const selectScroll = (e) => {
    setScrollTo(e.target.value);
    setShouldScroll(true);
  };

  const handleAutoSroll = (val) => {
    switch (val) {
      case CURRENT_RATIO:
        currentRatioSection.current?.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        break;
      case QUICK_RATIO:
        quickRatioSection.current?.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        break;
      case DEDT_EQUITY_RATIO:
        debtEqRatioSection.current?.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        break;
      case GROSS_MARGIN:
        grossMarginSection.current?.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        break;
      case NET_MARGIN:
        netMarginSection.current?.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        break;
      case SALES_TO_ASSETS:
        salesToAssetsSection.current?.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        break;
      case EBITDA:
        ebidtaSection.current?.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        break;
      case ROA:
        roaSection.current?.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        break;
      case ROI:
        roiSection.current?.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        break;
      case INVENTORY_TURNOVER:
        invTurnoverSection.current?.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        break;
      case AR_TURNOVER:
        arTurnoverSection.current?.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        break;
      case AP_TURNOVER:
        apTurnoverSection.current?.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        break;
      case DCOH:
        dcohSection.current.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        break;
      case DSCR:
        dscrSection.current.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        break;
      default:
        break;
    }
  };

  const handleOwnerGoalCheck = (e) => {
    if (!Object.keys(selectedFinancialVals).length) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Please select period first",
        },
      });
      clearSnack(dispatch);
      return;
    }

    setUseOwnerGoal(e.target.checked);
    if (!e.target.checked) {
      handleIndustryAvgCheck(indusAvgs);
      return;
    }

    const { ownerGoal } = selectedFinancialVals;

    const dummyAvgVals = {
      salesToAssets: ownerGoal.salesToAssets ? ownerGoal.salesToAssets : 0,
      grossProfitePercentage: parseInt(ownerGoal.grossProfitePercentage)
        ? parseInt(ownerGoal.grossProfitePercentage)
        : 0,
      netProfitePercentage: ownerGoal.netProfitePercentage
        ? ownerGoal.netProfitePercentage
        : 0,
      inventoryTurnoverRate: ownerGoal.inventoryTurnoverRate
        ? ownerGoal.inventoryTurnoverRate
        : 0,
      accountsReceivableTurnoverRate: ownerGoal.accountsReceivableTurnoverRate
        ? parseInt(ownerGoal.accountsReceivableTurnoverRate)
        : 0,
      accountsPayableTurnoverRate: parseInt(
        ownerGoal.accountsPayableTurnoverRate
      )
        ? parseInt(ownerGoal.accountsPayableTurnoverRate)
        : 0,
      ebidta: ownerGoal.ebidta ? ownerGoal.ebidta : 0,
      roa: ownerGoal.roa ? ownerGoal.roa : 0,
      roi: ownerGoal.roi ? ownerGoal.roi : 0,
      currentRatio: ownerGoal.currentRatio ? ownerGoal.currentRatio : 0,
      quickRatio: ownerGoal.quickRatio ? ownerGoal.quickRatio : 0,
      debtToEquityRatio: ownerGoal.debtToEquityRatio
        ? ownerGoal.debtToEquityRatio
        : 0,
    };

    setAvgVals(dummyAvgVals);
    calculateValues(dummyAvgVals);
  };

  const handleBackToTop = () => {
    topBarRef.current?.scrollIntoView({
      behavior: "auto",
      block: "start",
      inline: "nearest",
    });
  };

  const generateRandomLightColor = () => {
    return `#b7959b`;
  };

  const CompanyLogo = ({ companyName }) => {
    // Get the first letter of the company name
    const firstLetter = companyName ? companyName.charAt(0).toUpperCase() : "";

    // Style for the red circle
    const circleStyle = {
      width: "50px", // Set your desired logo size
      height: "50px", // Set your desired logo size
      borderRadius: "50%",
      backgroundColor: generateRandomLightColor(),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      fontSize: "1.5em",
      marginRight: "10px",
    };

    return <div style={circleStyle}>{firstLetter}</div>;
  };

  return (
    <React.Fragment>
      <Layout title="Financial Doctor">
        <CardWrapper>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
            }}
            ref={topBarRef}
          >
            <CompanyLogo companyName={company.companyName} />
            <Typography className="financial_data_heading">
              {company.companyName}
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "none",
                      backgroundColor: "#F8F8FF",
                      color: "gray",
                      paddingY: "10px",
                      paddingX: "20px",
                    },
                  },
                }}
                title={
                  <pre style={{ fontSize: "13px", fontWeight: "600" }}>
                    {company.NAICStitle}
                  </pre>
                }
                placement="bottom"
                // arrow
              >
                <Typography
                  className="financial_data_right_heading"
                  sx={{ cursor: "pointer" }}
                >
                  NAICS Code: {company.NAICScode}
                </Typography>
              </Tooltip>
            </Typography>
          </Grid>
        </CardWrapper>
        <Box style={{ height: 10, width: "100%" }} />

        <Grid
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Typography className="home_my_companies">
            The Financial Doctor
            <InfoOutlinedIcon
              onClick={() => handlInfo("financialDoctor")}
              className="infor_icon"
              style={{ marginLeft: "10px" }}
            />
          </Typography>

          <Grid>
            <Select
              placeHolder="Date Type "
              label="Date Type"
              className="financial_doctor_select_date_type"
              defaultValue={"Date Type"}
              onChange={handleSelectDateType}
            >
              <MenuItem disabled value={"Date Type"}>
                Date Type
              </MenuItem>
              {PERIOD_DURATION.map((dateType) => (
                <MenuItem value={dateType.label}>{dateType.label}</MenuItem>
              ))}
            </Select>

            <Select
              onChange={handlePeriodSelection}
              placeHolder="From Period "
              label="From Period "
              className="financial_data_select"
              defaultValue={"Period"}
              style={{ marginLeft: 25 }}
              disabled={!datePeriods.length}
            >
              <MenuItem disabled value={"Period"}>
                Period{" "}
                {dateLoading ? (
                  <CircularProgress style={{ marginLeft: "7px" }} size={20} />
                ) : (
                  ""
                )}
              </MenuItem>
              {datePeriods.length === 0 || !dateType ? (
                <MenuItem value="" disabled>
                  No Options Available
                </MenuItem>
              ) : (
                datePeriods.map((year, index) => (
                  <MenuItem key={index} value={year._id}>
                    {year.periodName}
                  </MenuItem>
                ))
              )}
            </Select>

            <Select
              style={{ marginLeft: "25px" }}
              className="financial_doctor_select_date_type"
              defaultValue={"Scroll To"}
              onChange={selectScroll}
            >
              <MenuItem disabled value={"Scroll To"}>
                Scroll To
              </MenuItem>
              {FDCalList.map((dateType) => (
                <MenuItem value={dateType.value}>{dateType.label}</MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Box style={{ height: 10, width: "100%" }} />
        {/* <CardWrapper></CardWrapper> */}

        <CardWrapper>
          {
            // !Object.keys(selectedFinancialVals).length ? (
            //   <Typography style={{ color: "#bfbfbf" }}>
            //     Select Date Type and Period
            //   </Typography>
            // ) : (
            <>
              <>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      onChange={handleOwnerGoalCheck}
                      checked={useOwnerGoal}
                      defaultChecked={useOwnerGoal}
                    />
                  }
                  label="Owner's Goals"
                  style={{
                    float: "right",
                    paddingLeft: "30px",
                    // visibility: "hidden",
                    // display: "none",
                  }}
                />
              </>
              <Grid
                container
                xs={12}
                className="card_wrapper_main"
                // style={{ border: border && border }}
              >
                {/*  */}
                {/* Current Ratio */}
                <Grid xs={12} ref={currentRatioGuageRef}>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 37,
                    }}
                  >
                    <Grid ref={currentRatioSection}>
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 25,
                        }}
                      >
                        {missingValError?.currentRatio ? (
                          <MissingValTooltip />
                        ) : (
                          ""
                        )}
                        Current Ratio:{" "}
                        {currentRatio && currentRatio !== Infinity
                          ? currentRatio
                          : 0}
                      </span>
                      <Box style={{ height: 5, width: "100%" }} />
                      <span
                        style={{
                          // fontWeight: "bolder",
                          color: "gray",
                          fontSize: 15,
                        }}
                      >
                        Your ability to support yourself this year
                      </span>
                    </Grid>
                    <Grid
                      style={{
                        fontSize: 25,
                        display: "flex",
                      }}
                    >
                      Grade:{" "}
                      <TooltipMenu
                        getColor={getColor}
                        value={currentRatioGrade}
                        options={gradeOptions}
                        onChange={handleGradeChange}
                        calculation={CURRENT_RATIO}
                      />
                    </Grid>
                  </Grid>
                  {/*  */}
                  <Box style={{ height: 20, width: "100%" }} />
                  <CardWrapper border="1px solid #E7E7E7">
                    <ResponsiveGrid
                      currentRatioScale={currentRatioScale}
                      currentVal={currentRatio}
                      industryAvg={avgVals?.currentRatio?.toFixed(2)}
                    />
                  </CardWrapper>
                </Grid>
                <Box style={{ height: 30, width: "100%" }} />
                <CardWrapper border="1px solid #E7E7E7">
                  <Grid container xs={12}>
                    <Grid xs={12} ref={currentRatioChartRef}>
                      <Box style={{ height: 30, width: "100%" }} />
                      <span
                        style={{
                          // fontWeight: "bolder",
                          fontSize: 25,
                        }}
                      >
                        Historical Graph over time
                      </span>
                      <Box
                        // ref={trendsRef}
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <CurrentRatioChart
                              currentRatio={historicalValues?.currentRatio}
                              dates={historicalValues?.dates}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>

                    <Box style={{ height: 30, width: "100%" }} />
                    <div
                      ref={currentRatioReportRef}
                      style={{
                        backgroundColor: "#EBF8FC",
                        padding: "20px",
                        borderRadius: "20px",
                      }}
                      className="full-width"
                    >
                      {/* <Box style={{ height: 30, width: "100%" }} /> */}
                      <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 16,
                          }}
                        >
                          <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                            }}
                          >
                            Analysis
                          </span>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bolder",
                            }}
                          >
                            {currentRatioComparisonDetails?.headline}
                          </span>{" "}
                        </span>
                        <span
                          style={{
                            fontSize: 16,
                            color: "#606060",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Conclusions:
                          </span>{" "}
                          <Typography>
                            {currentRatioComparisonDetails?.conclusion}
                          </Typography>
                        </span>
                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Suggestions:
                          </span>{" "}
                          <Typography>
                            {currentRatioComparisonDetails?.suggestion}
                          </Typography>
                        </span>
                      </Typography>
                    </div>
                    <Grid item xs={12}>
                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <Grid container xs={12}>
                        <Accordian
                          noteData={currentRatioNotes}
                          key1="currentRatioChart"
                          selectedDate={selectedFinancialVals.year}
                          notesRef={currentRatioNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardWrapper>
                <Box style={{ height: 30, width: "100%" }} />
                {/* Current Ration end here */}
                {/* Quick Ratio */}
                <Grid xs={12} ref={quickRatioGuageRef}>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 37,
                    }}
                  >
                    <Grid ref={quickRatioSection}>
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 25,
                        }}
                      >
                        {missingValError?.quickRatio ? (
                          <MissingValTooltip />
                        ) : (
                          ""
                        )}
                        Quick Ratio:{" "}
                        {quickRatio && quickRatio !== Infinity ? quickRatio : 0}
                      </span>
                      <Box style={{ height: 5, width: "100%" }} />
                      <span
                        style={{
                          // fontWeight: "bolder",
                          color: "gray",
                          fontSize: 15,
                        }}
                      >
                        Your company's ability to support itself this quarter
                      </span>
                    </Grid>
                    <Grid
                      style={{
                        fontSize: 25,
                        display: "flex",
                      }}
                    >
                      Grade:{" "}
                      <TooltipMenu
                        getColor={getColor}
                        value={quickRatioGrade}
                        options={gradeOptions}
                        onChange={handleGradeChange}
                        calculation={QUICK_RATIO}
                      />
                    </Grid>
                  </Grid>
                  <Box style={{ height: 20, width: "100%" }} />
                  <CardWrapper border="1px solid #E7E7E7">
                    <ResponsiveGrid
                      currentRatioScale={quickRatioSclae}
                      currentVal={quickRatio}
                      industryAvg={avgVals?.quickRatio?.toFixed(2)}
                    />
                  </CardWrapper>
                </Grid>
                <Box style={{ height: 30, width: "100%" }} />
                <CardWrapper border="1px solid #E7E7E7">
                  <Grid container xs={12}>
                    <Box style={{ height: 30, width: "100%" }} />
                    <Grid xs={12} ref={quickRatioChartRef}>
                      <span
                        style={{
                          // fontWeight: "bolder",
                          fontSize: 25,
                        }}
                      >
                        Historical Graph over time
                      </span>
                      <Box
                        // ref={trendsRef}
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <QuickRatioChart
                              quickRatio={historicalValues?.quickRatio}
                              dates={historicalValues?.dates}
                              label={"Quick Ratio"}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Box style={{ height: 30, width: "100%" }} />
                    <div
                      ref={quickRatioReportRef}
                      style={{
                        backgroundColor: "#EBF8FC",
                        padding: "20px",
                        borderRadius: "20px",
                      }}
                      className="full-width"
                    >
                      {/* <Box style={{ height: 30, width: "100%" }} /> */}
                      <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 16,
                          }}
                        >
                          <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                            }}
                          >
                            Analysis
                          </span>
                        </span>

                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bolder",
                            }}
                          >
                            {quickRatioComparisonDetails?.headline}
                          </span>{" "}
                        </span>

                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Conclusions:
                          </span>{" "}
                          <Typography>
                            {quickRatioComparisonDetails?.conclusion}
                          </Typography>
                        </span>
                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Suggestions:
                          </span>{" "}
                          <Typography>
                            {quickRatioComparisonDetails?.suggestion}
                          </Typography>
                        </span>
                      </Typography>
                    </div>
                    <Grid item xs={12}>
                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <Grid container xs={12}>
                        <Accordian
                          noteData={quickRatioNotes}
                          key1="quickRatioChart"
                          selectedDate={selectedFinancialVals.year}
                          notesRef={quickRatioNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardWrapper>
                <Box style={{ height: 30, width: "100%" }} />
                {/* Quick Ratio end here*/}
                {/* Debt to equity ratio */}
                <Grid xs={12} ref={debtEqRatioGuageRef}>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 37,
                    }}
                  >
                    <Grid ref={debtEqRatioSection}>
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 25,
                        }}
                      >
                        {missingValError?.debtEquityRatio ? (
                          <MissingValTooltip />
                        ) : (
                          ""
                        )}
                        Debt To Equity Ratio:{" "}
                        {debtEquityRatio && debtEquityRatio !== Infinity
                          ? debtEquityRatio
                          : 0}
                      </span>
                      <Box style={{ height: 5, width: "100%" }} />
                      <span
                        style={{
                          color: "gray",
                          fontSize: 15,
                        }}
                      >
                        The amount of skin you have in the game compared to the
                        bank.
                      </span>
                    </Grid>
                    <Grid
                      style={{
                        fontSize: 25,
                        display: "flex",
                      }}
                    >
                      Grade:{" "}
                      <TooltipMenu
                        getColor={getColor}
                        value={debtEquityRatioGrade}
                        options={gradeOptions}
                        onChange={handleGradeChange}
                        calculation={DEDT_EQUITY_RATIO}
                      />
                    </Grid>
                  </Grid>

                  <Box style={{ height: 20, width: "100%" }} />
                  <CardWrapper border="1px solid #E7E7E7">
                    <CustomAppBarDebtEquityRatio
                      currentRatioScale={debtEquityRatioScale}
                      currentVal={debtEquityRatio}
                      industryAvg={avgVals?.debtToEquityRatio?.toFixed(2)}
                    />
                  </CardWrapper>
                </Grid>
                <Box style={{ height: 30, width: "100%" }} />

                <CardWrapper border="1px solid #E7E7E7">
                  <Grid container xs={12}>
                    <Box style={{ height: 30, width: "100%" }} />
                    <Grid xs={12} ref={debtEqRatioChartRef}>
                      <span
                        style={{
                          fontSize: 25,
                        }}
                      >
                        Historical Graph over time
                      </span>
                      <Box
                        // ref={trendsRef}
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            {/* using for debt to equity ratio */}
                            <QuickRatioChart
                              quickRatio={historicalValues?.debtToEquityRatio}
                              dates={historicalValues?.dates}
                              label={"Debt To Equity Ratio"}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Box style={{ height: 30, width: "100%" }} />
                    <div
                      ref={debtEqRatioReportRef}
                      style={{
                        backgroundColor: "#EBF8FC",
                        padding: "20px",
                        borderRadius: "20px",
                      }}
                      className="full-width"
                    >
                      {/* <Box style={{ height: 30, width: "100%" }} /> */}
                      <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 16,
                          }}
                        >
                          <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                            }}
                          >
                            Analysis
                          </span>
                        </span>

                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bolder",
                            }}
                          >
                            {debtEquityRatioComparisonDetails?.headline}
                          </span>{" "}
                        </span>

                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Conclusions:
                          </span>{" "}
                          <Typography>
                            {debtEquityRatioComparisonDetails?.conclusion}
                          </Typography>
                        </span>
                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Suggestions:
                          </span>{" "}
                          <Typography>
                            {debtEquityRatioComparisonDetails?.suggestion}
                          </Typography>
                        </span>
                      </Typography>
                    </div>
                    <Grid item xs={12}>
                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <Grid container xs={12}>
                        <Accordian
                          noteData={debtEqRatioNotes}
                          key1="debtEqRatioChart"
                          selectedDate={selectedFinancialVals.year}
                          notesRef={debtEqRatioNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardWrapper>
                <Box style={{ height: 30, width: "100%" }} />

                {/* Debt to equity ratio end here */}

                {/* gross margin start here */}
                <Grid xs={12} ref={grossMarginGuageRef}>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 37,
                    }}
                  >
                    <Grid ref={grossMarginSection}>
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 25,
                        }}
                      >
                        {missingValError?.grossMargin ? (
                          <MissingValTooltip />
                        ) : (
                          ""
                        )}
                        Gross Profit Margin:{" "}
                        {grossMargin && grossMargin !== Infinity
                          ? grossMargin
                          : 0}
                        %
                      </span>
                      <Box style={{ height: 5, width: "100%" }} />
                      <span
                        style={{
                          // fontWeight: "bolder",
                          color: "gray",
                          fontSize: 15,
                        }}
                      >
                        How much money will you make based on the mark up from
                        cost of your items.
                      </span>
                    </Grid>
                    <Grid
                      style={{
                        fontSize: 25,
                        display: "flex",
                      }}
                    >
                      Grade:{" "}
                      <TooltipMenu
                        getColor={getColor}
                        value={grossMarginGrade}
                        options={gradeOptions}
                        onChange={handleGradeChange}
                        calculation={`${GROSS_MARGIN}`}
                      />
                    </Grid>
                  </Grid>
                  <Box style={{ height: 20, width: "100%" }} />
                  <CardWrapper border="1px solid #E7E7E7">
                    <CustomAppBarGrossMargin
                      currentRatioScale={grossMarginScale}
                      currentVal={grossMargin}
                      industryAvg={`${parseInt(
                        avgVals?.grossProfitePercentage
                      )?.toFixed(2)}%`}
                    />
                  </CardWrapper>
                </Grid>
                <Box style={{ height: 30, width: "100%" }} />

                <CardWrapper border="1px solid #E7E7E7">
                  <Grid container xs={12}>
                    <Box style={{ height: 30, width: "100%" }} />
                    <Grid xs={12} ref={grossMarginChartRef}>
                      <span
                        style={{
                          fontSize: 25,
                        }}
                      >
                        Historical Graph over time
                      </span>
                      <Box
                        // ref={trendsRef}
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            {/* using for gross margin */}
                            <QuickRatioChart
                              quickRatio={historicalValues?.grossMargin}
                              dates={historicalValues?.dates}
                              label={"Gross Profit Margin"}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Box style={{ height: 30, width: "100%" }} />
                    <div
                      ref={grossMarginReportRef}
                      style={{
                        backgroundColor: "#EBF8FC",
                        padding: "20px",
                        borderRadius: "20px",
                      }}
                      className="full-width"
                    >
                      {/* <Box style={{ height: 30, width: "100%" }} /> */}
                      <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 16,
                          }}
                        >
                          <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                            }}
                          >
                            Analysis
                          </span>
                        </span>

                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bolder",
                            }}
                          >
                            {grossMarginComparisonDetails?.headline}
                          </span>{" "}
                        </span>

                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Conclusions:
                          </span>{" "}
                          <Typography>
                            {grossMarginComparisonDetails?.conclusion}
                          </Typography>
                        </span>
                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Suggestions:
                          </span>{" "}
                          <Typography>
                            {grossMarginComparisonDetails?.suggestion}
                          </Typography>
                        </span>
                      </Typography>
                    </div>

                    <Grid item xs={12}>
                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <Grid container xs={12}>
                        <Accordian
                          noteData={grossMarginNotes}
                          key1="grossMarginChart"
                          selectedDate={selectedFinancialVals.year}
                          notesRef={grossMarginNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardWrapper>
                <Box style={{ height: 30, width: "100%" }} />
                {/* gross margin ends here */}

                {/* net margin starts here */}
                <Grid xs={12} ref={netMarginGuageRef}>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 37,
                    }}
                  >
                    <Grid ref={netMarginSection}>
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 25,
                        }}
                      >
                        {missingValError?.netMargin ? (
                          <MissingValTooltip />
                        ) : (
                          ""
                        )}
                        Net Profit Margin:{" "}
                        {netMargin && netMargin !== Infinity ? netMargin : 0}%
                      </span>
                      <Box style={{ height: 5, width: "100%" }} />
                      <span
                        style={{
                          color: "gray",
                          fontSize: 15,
                        }}
                      >
                        Indicates how effective your company is at cost control.
                      </span>
                    </Grid>

                    <Grid
                      style={{
                        fontSize: 25,
                        display: "flex",
                      }}
                    >
                      Grade:{" "}
                      <TooltipMenu
                        getColor={getColor}
                        value={netMarginGrade}
                        options={gradeOptions}
                        onChange={handleGradeChange}
                        calculation={`${NET_MARGIN}`}
                      />
                    </Grid>
                  </Grid>
                  <Box style={{ height: 20, width: "100%" }} />
                  <CardWrapper border="1px solid #E7E7E7">
                    <CustomAppBarNetMargin
                      currentRatioScale={netMarginScale}
                      currentVal={netMargin}
                      industryAvg={`${avgVals?.netProfitePercentage?.toFixed(
                        2
                      )}%`}
                    />
                  </CardWrapper>
                </Grid>
                <Box style={{ height: 30, width: "100%" }} />

                <CardWrapper border="1px solid #E7E7E7">
                  <Grid container xs={12}>
                    <Box style={{ height: 30, width: "100%" }} />
                    <Grid xs={12} ref={netMarginChartRef}>
                      <span
                        style={{
                          fontSize: 25,
                        }}
                      >
                        Historical Graph over time
                      </span>
                      <Box
                        // ref={trendsRef}
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            {/* using for net margin */}
                            <QuickRatioChart
                              quickRatio={historicalValues?.netMargin}
                              dates={historicalValues?.dates}
                              label={"Net Profit Margin"}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Box style={{ height: 30, width: "100%" }} />
                    <div
                      ref={netMarginReportRef}
                      style={{
                        backgroundColor: "#EBF8FC",
                        padding: "20px",
                        borderRadius: "20px",
                      }}
                      className="full-width"
                    >
                      {/* <Box style={{ height: 30, width: "100%" }} /> */}
                      <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 16,
                          }}
                        >
                          <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                            }}
                          >
                            Analysis
                          </span>
                        </span>

                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bolder",
                            }}
                          >
                            {netMarginComparisonDetails?.headline}
                          </span>{" "}
                        </span>

                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Conclusions:
                          </span>{" "}
                          <Typography>
                            {netMarginComparisonDetails?.conclusion}
                          </Typography>
                        </span>
                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Suggestions:
                          </span>{" "}
                          <Typography>
                            {netMarginComparisonDetails?.suggestion}
                          </Typography>
                        </span>
                      </Typography>
                    </div>

                    <Grid item xs={12}>
                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <Grid container xs={12}>
                        <Accordian
                          noteData={netMarginNotes}
                          key1="netMarginChart"
                          selectedDate={selectedFinancialVals.year}
                          notesRef={netMarginNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardWrapper>
                <Box style={{ height: 30, width: "100%" }} />

                {/* net margin ends here */}

                {/* sales to assets starts here */}
                <Grid xs={12} ref={saleAssetsGuageRef}>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 37,
                    }}
                  >
                    <Grid ref={salesToAssetsSection}>
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 25,
                        }}
                      >
                        {missingValError?.salesToAssets ? (
                          <MissingValTooltip />
                        ) : (
                          ""
                        )}
                        Sales To Assets:{" "}
                        {salesToAssets && salesToAssets !== Infinity
                          ? salesToAssets
                          : 0}
                      </span>
                      <Box style={{ height: 5, width: "100%" }} />
                      <span
                        style={{
                          color: "gray",
                          fontSize: 15,
                        }}
                      >
                        This is your company's ability to generate sales with
                        your assets.{" "}
                      </span>
                    </Grid>

                    <Grid
                      style={{
                        fontSize: 25,
                        display: "flex",
                      }}
                    >
                      Grade:{" "}
                      <TooltipMenu
                        getColor={getColor}
                        value={salesToAssetsGrade}
                        options={gradeOptions}
                        onChange={handleGradeChange}
                        calculation={SALES_TO_ASSETS}
                      />
                    </Grid>
                  </Grid>
                  <Box style={{ height: 20, width: "100%" }} />
                  <CardWrapper border="1px solid #E7E7E7">
                    <CustomAppBarSalesToAssets
                      currentRatioScale={salesToAssetsScale}
                      currentVal={salesToAssets}
                      industryAvg={avgVals?.salesToAssets?.toFixed(2)}
                    />
                  </CardWrapper>
                </Grid>
                <Box style={{ height: 30, width: "100%" }} />

                <CardWrapper border="1px solid #E7E7E7">
                  <Grid container xs={12}>
                    <Box style={{ height: 30, width: "100%" }} />
                    <Grid xs={12} ref={saleAssetsChartRef}>
                      <span
                        style={{
                          fontSize: 25,
                        }}
                      >
                        Historical Graph over time
                      </span>
                      <Box
                        // ref={trendsRef}
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            {/* using for sales to assets */}
                            <QuickRatioChart
                              quickRatio={historicalValues?.salesToAssets}
                              dates={historicalValues?.dates}
                              label={"Sales To Assets"}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Box style={{ height: 30, width: "100%" }} />
                    <div
                      ref={saleAssetsReportRef}
                      style={{
                        backgroundColor: "#EBF8FC",
                        padding: "20px",
                        borderRadius: "20px",
                      }}
                      className="full-width"
                    >
                      {/* <Box style={{ height: 30, width: "100%" }} /> */}
                      <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 16,
                          }}
                        >
                          <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                            }}
                          >
                            Analysis
                          </span>
                        </span>

                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bolder",
                            }}
                          >
                            {salesToAssetsComparisonDetails?.headline}
                          </span>{" "}
                        </span>

                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Conclusions:
                          </span>{" "}
                          <Typography>
                            {salesToAssetsComparisonDetails?.conclusion}
                          </Typography>
                        </span>
                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Suggestions:
                          </span>{" "}
                          <Typography>
                            {salesToAssetsComparisonDetails?.suggestion}
                          </Typography>
                        </span>
                      </Typography>
                    </div>

                    <Grid item xs={12}>
                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <Grid container xs={12}>
                        <Accordian
                          noteData={saleAssetsNotes}
                          key1="saleAssetsChart"
                          selectedDate={selectedFinancialVals.year}
                          notesRef={saleAssetsNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardWrapper>
                <Box style={{ height: 30, width: "100%" }} />
                {/* sales to assets ends here */}

                {/* EBITDA starts here*/}

                <Grid xs={12} ref={ebidtaFDGuageRef}>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 37,
                    }}
                  >
                    <Grid ref={ebidtaSection}>
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 25,
                        }}
                      >
                        {missingValError?.ebidta ? <MissingValTooltip /> : ""}
                        EBITDA: {ebidta && ebidta !== Infinity ? ebidta : 0}%
                      </span>
                      <Box style={{ height: 5, width: "100%" }} />
                      <span
                        style={{
                          color: "gray",
                          fontSize: 15,
                        }}
                      >
                        This is the cash flow achieved by your company from your
                        operations.
                      </span>
                    </Grid>

                    <Grid
                      style={{
                        fontSize: 25,
                        display: "flex",
                      }}
                    >
                      Grade:{" "}
                      <TooltipMenu
                        getColor={getColor}
                        value={ebidtaGrade}
                        options={gradeOptions}
                        onChange={handleGradeChange}
                        calculation={EBITDA}
                      />
                    </Grid>
                  </Grid>
                  <Box style={{ height: 20, width: "100%" }} />
                  <CardWrapper border="1px solid #E7E7E7">
                    <CustomAppBarEbitda
                      currentRatioScale={ebidtaScale}
                      currentVal={ebidta}
                      industryAvg={`${avgVals?.ebidta?.toFixed(2)}%`}
                    />
                  </CardWrapper>
                </Grid>
                <Box style={{ height: 30, width: "100%" }} />

                <CardWrapper border="1px solid #E7E7E7">
                  <Grid container xs={12}>
                    <Box style={{ height: 30, width: "100%" }} />
                    <Grid xs={12} ref={ebidtaFDChartRef}>
                      <span
                        style={{
                          fontSize: 25,
                        }}
                      >
                        Historical Graph over time
                      </span>
                      <Box
                        // ref={trendsRef}
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            {/* using for ebitda */}
                            <QuickRatioChart
                              quickRatio={historicalValues?.ebitda}
                              dates={historicalValues?.dates}
                              label={"EBITDA"}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Box style={{ height: 30, width: "100%" }} />
                    <div
                      ref={ebidtaFDReportRef}
                      style={{
                        backgroundColor: "#EBF8FC",
                        padding: "20px",
                        borderRadius: "20px",
                      }}
                      className="full-width"
                    >
                      {/* <Box style={{ height: 30, width: "100%" }} /> */}
                      <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 16,
                          }}
                        >
                          <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                            }}
                          >
                            Analysis
                          </span>
                        </span>

                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bolder",
                            }}
                          >
                            {ebidtaComparisonDetails?.headline}
                          </span>{" "}
                        </span>

                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Conclusions:
                          </span>{" "}
                          <Typography>
                            {ebidtaComparisonDetails?.conclusion}
                          </Typography>
                        </span>
                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Suggestions:
                          </span>{" "}
                          <Typography>
                            {ebidtaComparisonDetails?.suggestion}
                          </Typography>
                        </span>
                      </Typography>
                    </div>

                    <Grid item xs={12}>
                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <Grid container xs={12}>
                        <Accordian
                          noteData={ebidtaFDNotes}
                          key1="ebidtaFDChart"
                          selectedDate={selectedFinancialVals.year}
                          notesRef={ebidtaFDNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardWrapper>
                <Box style={{ height: 30, width: "100%" }} />

                {/* EBITDA ends here*/}

                {/* ROA starts here */}
                <Grid xs={12} ref={roaGuageRef}>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 37,
                    }}
                  >
                    <Grid ref={roaSection}>
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 25,
                        }}
                      >
                        {missingValError?.roa ? <MissingValTooltip /> : ""}
                        Return On Assets: {roa && roa !== Infinity ? roa : 0}%
                      </span>
                      <Box style={{ height: 5, width: "100%" }} />
                      <span
                        style={{
                          color: "gray",
                          fontSize: 15,
                        }}
                      >
                        How well does your company generate profit from your
                        assets
                      </span>
                    </Grid>
                    <Grid
                      style={{
                        fontSize: 25,
                        display: "flex",
                      }}
                    >
                      Grade:{" "}
                      <TooltipMenu
                        getColor={getColor}
                        value={roaGrade}
                        options={gradeOptions}
                        onChange={handleGradeChange}
                        calculation={ROA}
                      />
                    </Grid>
                  </Grid>

                  <Box style={{ height: 20, width: "100%" }} />
                  <CardWrapper border="1px solid #E7E7E7">
                    <CustomAppBarRoa
                      currentRatioScale={roaScale}
                      currentVal={roa}
                      industryAvg={`${avgVals?.roa?.toFixed(2)}%`}
                    />
                  </CardWrapper>
                </Grid>
                <Box style={{ height: 30, width: "100%" }} />

                <CardWrapper border="1px solid #E7E7E7">
                  <Grid container xs={12}>
                    <Box style={{ height: 30, width: "100%" }} />
                    <Grid xs={12} ref={roaChartRef}>
                      <span
                        style={{
                          fontSize: 25,
                        }}
                      >
                        Historical Graph over time
                      </span>
                      <Box
                        // ref={trendsRef}
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            {/* using for roa */}
                            <QuickRatioChart
                              quickRatio={historicalValues?.roa}
                              dates={historicalValues?.dates}
                              label={"ROA"}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Box style={{ height: 30, width: "100%" }} />
                    <div
                      ref={roaReportRef}
                      style={{
                        backgroundColor: "#EBF8FC",
                        padding: "20px",
                        borderRadius: "20px",
                      }}
                      className="full-width"
                    >
                      {/* <Box style={{ height: 30, width: "100%" }} /> */}
                      <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 16,
                          }}
                        >
                          <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                            }}
                          >
                            Analysis
                          </span>
                        </span>

                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bolder",
                            }}
                          >
                            {roaComparisonDetails?.headline}
                          </span>{" "}
                        </span>

                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Conclusions:
                          </span>{" "}
                          <Typography>
                            {roaComparisonDetails?.conclusion}
                          </Typography>
                        </span>
                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Suggestions:
                          </span>{" "}
                          <Typography>
                            {roaComparisonDetails?.suggestion}
                          </Typography>
                        </span>
                      </Typography>
                    </div>

                    <Grid item xs={12}>
                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <Grid container xs={12}>
                        <Accordian
                          noteData={roaNotes}
                          key1="roaChart"
                          selectedDate={selectedFinancialVals.year}
                          notesRef={roaNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardWrapper>
                <Box style={{ height: 30, width: "100%" }} />

                {/* ROA end here */}

                {/* ROI start here */}
                <Grid xs={12} ref={roiGuageRef}>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 37,
                    }}
                  >
                    <Grid ref={roiSection}>
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 25,
                        }}
                      >
                        {missingValError?.roi ? <MissingValTooltip /> : ""}
                        Return On Investment:{" "}
                        {roi && roi !== Infinity ? roi : 0}%
                      </span>
                      <Box style={{ height: 5, width: "100%" }} />
                      <span
                        style={{
                          color: "gray",
                          fontSize: 15,
                        }}
                      >
                        Helps determine if your investment in the company was
                        worth it.
                      </span>
                    </Grid>

                    <Grid
                      style={{
                        fontSize: 25,
                        display: "flex",
                      }}
                    >
                      Grade:{" "}
                      <TooltipMenu
                        getColor={getColor}
                        value={roiGrade}
                        options={gradeOptions}
                        onChange={handleGradeChange}
                        calculation={ROI}
                      />
                    </Grid>
                  </Grid>

                  <Box style={{ height: 20, width: "100%" }} />
                  <CardWrapper border="1px solid #E7E7E7">
                    <CustomAppBarRoa
                      currentRatioScale={roiScale}
                      currentVal={roi}
                      industryAvg={avgVals?.roi?.toFixed(2)}
                    />
                  </CardWrapper>
                </Grid>
                <Box style={{ height: 30, width: "100%" }} />

                <CardWrapper border="1px solid #E7E7E7">
                  <Grid container xs={12}>
                    <Box style={{ height: 30, width: "100%" }} />
                    <Grid xs={12} ref={roiChartRef}>
                      <span
                        style={{
                          fontSize: 25,
                        }}
                      >
                        Historical Graph over time
                      </span>
                      <Box
                        // ref={trendsRef}
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            {/* using for ROI */}
                            <QuickRatioChart
                              quickRatio={historicalValues?.roi}
                              dates={historicalValues?.dates}
                              label={"ROI"}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Box style={{ height: 30, width: "100%" }} />
                    <div
                      ref={roiReportRef}
                      style={{
                        backgroundColor: "#EBF8FC",
                        padding: "20px",
                        borderRadius: "20px",
                      }}
                      className="full-width"
                    >
                      {/* <Box style={{ height: 30, width: "100%" }} /> */}
                      <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 16,
                          }}
                        >
                          <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                            }}
                          >
                            Analysis
                          </span>
                        </span>

                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bolder",
                            }}
                          >
                            {roiComparisonDetails?.headline}
                          </span>{" "}
                        </span>

                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Conclusions:
                          </span>{" "}
                          <Typography>
                            {roiComparisonDetails?.conclusion}
                          </Typography>
                        </span>
                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Suggestions:
                          </span>{" "}
                          <Typography>
                            {roiComparisonDetails?.suggestion}
                          </Typography>
                        </span>
                      </Typography>
                    </div>

                    <Grid item xs={12}>
                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <Grid container xs={12}>
                        <Accordian
                          noteData={roiNotes}
                          key1="roiChart"
                          selectedDate={selectedFinancialVals.year}
                          notesRef={roiNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardWrapper>
                <Box style={{ height: 30, width: "100%" }} />

                {/* ROI end here */}

                {/* inventory turnover start here */}
                <Grid xs={12} ref={invTurnoverGuageRef}>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 37,
                    }}
                  >
                    <Grid ref={invTurnoverSection}>
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 25,
                        }}
                      >
                        {missingValError?.inventoryTurnoverRate ? (
                          <MissingValTooltip />
                        ) : (
                          ""
                        )}
                        Inventory Days:{" "}
                        {inventoryTurnover && inventoryTurnover !== Infinity
                          ? inventoryTurnover
                          : 0}
                      </span>
                      <Box style={{ height: 5, width: "100%" }} />
                      <span
                        style={{
                          color: "gray",
                          fontSize: 15,
                        }}
                      >
                        How long does Inventory sit on the shelf on avergae.
                      </span>
                    </Grid>
                    <Grid
                      style={{
                        fontSize: 25,
                        display: "flex",
                      }}
                    >
                      Grade:{" "}
                      <TooltipMenu
                        getColor={getColor}
                        value={inventoryTurnoverGrade}
                        options={gradeOptions}
                        onChange={handleGradeChange}
                        calculation={INVENTORY_TURNOVER}
                      />
                    </Grid>
                  </Grid>

                  <Box style={{ height: 20, width: "100%" }} />
                  <CardWrapper border="1px solid #E7E7E7">
                    <CustomAppBarInventoryTurnover
                      currentRatioScale={inventoryTurnoverScale}
                      currentVal={inventoryTurnover}
                      industryAvg={
                        avgVals?.inventoryTurnoverRate
                          ? (365 / avgVals?.inventoryTurnoverRate)?.toFixed(0)
                          : 0
                      }
                    />
                  </CardWrapper>
                </Grid>
                <Box style={{ height: 30, width: "100%" }} />

                <CardWrapper border="1px solid #E7E7E7">
                  <Grid container xs={12}>
                    <Box style={{ height: 30, width: "100%" }} />
                    <Grid xs={12} ref={invTurnoverChartRef}>
                      <span
                        style={{
                          fontSize: 25,
                        }}
                      >
                        Historical Graph over time
                      </span>
                      <Box
                        // ref={trendsRef}
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            {/* using for inventory turnover */}
                            <QuickRatioChart
                              quickRatio={historicalValues?.inventoryTurnover}
                              dates={historicalValues?.dates}
                              label={"Inventory Days"}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Box style={{ height: 30, width: "100%" }} />
                    <div
                      ref={invTurnoverReportRef}
                      style={{
                        backgroundColor: "#EBF8FC",
                        padding: "20px",
                        borderRadius: "20px",
                      }}
                      className="full-width"
                    >
                      {/* <Box style={{ height: 30, width: "100%" }} /> */}
                      <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 16,
                          }}
                        >
                          <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                            }}
                          >
                            Analysis
                          </span>
                        </span>

                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bolder",
                            }}
                          >
                            {inventoryTurnoverComparisonDetails?.headline}
                          </span>{" "}
                        </span>

                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Conclusions:
                          </span>{" "}
                          <Typography>
                            {inventoryTurnoverComparisonDetails?.conclusion}
                          </Typography>
                        </span>
                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Suggestions:
                          </span>{" "}
                          <Typography>
                            {inventoryTurnoverComparisonDetails?.suggestion}
                          </Typography>
                        </span>
                      </Typography>
                    </div>

                    <Grid item xs={12}>
                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <Grid container xs={12}>
                        <Accordian
                          noteData={invTurnoverNotes}
                          key1="invTurnoverChart"
                          selectedDate={selectedFinancialVals.year}
                          notesRef={invTurnoverNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardWrapper>
                <Box style={{ height: 30, width: "100%" }} />

                {/* inventory turnover end here */}

                {/* AR Turnover starts here */}
                <Grid xs={12} ref={arTurnoverGuageRef}>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 37,
                    }}
                  >
                    <Grid ref={arTurnoverSection}>
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 25,
                        }}
                      >
                        {missingValError?.arTurnoverRate ? (
                          <MissingValTooltip />
                        ) : (
                          ""
                        )}
                        AR Days:{" "}
                        {arTurnover && arTurnover !== Infinity ? arTurnover : 0}
                      </span>
                      <Box style={{ height: 5, width: "100%" }} />
                      <span
                        style={{
                          color: "gray",
                          fontSize: 15,
                        }}
                      >
                        How long do you wait for customers to pay an invoice on
                        avergae
                      </span>
                    </Grid>
                    <Grid
                      style={{
                        fontSize: 25,
                        display: "flex",
                      }}
                    >
                      Grade:{" "}
                      <TooltipMenu
                        getColor={getColor}
                        value={arTurnoverGrade}
                        options={gradeOptions}
                        onChange={handleGradeChange}
                        calculation={AR_TURNOVER}
                      />
                    </Grid>
                  </Grid>

                  <Box style={{ height: 20, width: "100%" }} />
                  <CardWrapper border="1px solid #E7E7E7">
                    {/* using for AR turnover */}
                    <CustomAppBarInventoryTurnover
                      currentRatioScale={arTurnoverScale}
                      currentVal={arTurnover}
                      industryAvg={(
                        365 / avgVals?.accountsReceivableTurnoverRate
                      )?.toFixed(0)}
                    />
                  </CardWrapper>
                </Grid>
                <Box style={{ height: 30, width: "100%" }} />

                <CardWrapper border="1px solid #E7E7E7">
                  <Grid container xs={12}>
                    <Box style={{ height: 30, width: "100%" }} />
                    <Grid xs={12} ref={arTurnoverChartRef}>
                      <span
                        style={{
                          fontSize: 25,
                        }}
                      >
                        Historical Graph over time
                      </span>
                      <Box
                        // ref={trendsRef}
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            {/* using for ar turnover */}
                            <QuickRatioChart
                              quickRatio={historicalValues?.arTurnover}
                              dates={historicalValues?.dates}
                              label={"AR Days"}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Box style={{ height: 30, width: "100%" }} />
                    <div
                      ref={arTurnoverReportRef}
                      style={{
                        backgroundColor: "#EBF8FC",
                        padding: "20px",
                        borderRadius: "20px",
                      }}
                      className="full-width"
                    >
                      {/* <Box style={{ height: 30, width: "100%" }} /> */}
                      <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 16,
                          }}
                        >
                          <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                            }}
                          >
                            Analysis
                          </span>
                        </span>

                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bolder",
                            }}
                          >
                            {arTurnoverComparisonDetails?.headline}
                          </span>{" "}
                        </span>

                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Conclusions:
                          </span>{" "}
                          <Typography>
                            {arTurnoverComparisonDetails?.conclusion}
                          </Typography>
                        </span>
                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Suggestions:
                          </span>{" "}
                          <Typography>
                            {arTurnoverComparisonDetails?.suggestion}
                          </Typography>
                        </span>
                      </Typography>
                    </div>

                    <Grid item xs={12}>
                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <Grid container xs={12}>
                        <Accordian
                          noteData={arTurnoverNotes}
                          key1="arTurnoverChart"
                          selectedDate={selectedFinancialVals.year}
                          notesRef={arTurnoverNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardWrapper>
                <Box style={{ height: 30, width: "100%" }} />

                {/* AR Turnover end here */}

                {/* AP turnover start here */}
                <Grid xs={12} ref={apTurnoverGuageRef}>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 37,
                    }}
                  >
                    <Grid ref={apTurnoverSection}>
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 25,
                        }}
                      >
                        {missingValError?.apTurnoverRate ? (
                          <MissingValTooltip />
                        ) : (
                          ""
                        )}
                        AP Days:{" "}
                        {apTurnover && apTurnover !== Infinity ? apTurnover : 0}
                      </span>
                      <Box style={{ height: 5, width: "100%" }} />
                      <span
                        style={{
                          color: "gray",
                          fontSize: 15,
                        }}
                      >
                        How long does it take you to pay an invoice on average.
                      </span>
                    </Grid>

                    <Grid
                      style={{
                        fontSize: 25,
                        display: "flex",
                      }}
                    >
                      Grade:{" "}
                      <TooltipMenu
                        getColor={getColor}
                        value={apTurnoverGrade}
                        options={gradeOptions}
                        onChange={handleGradeChange}
                        calculation={AP_TURNOVER}
                      />
                    </Grid>
                  </Grid>

                  <Box style={{ height: 20, width: "100%" }} />
                  <CardWrapper border="1px solid #E7E7E7">
                    {/* using for AP Turnover */}
                    <CustomAppBarRoa
                      currentRatioScale={apTurnoverScale}
                      currentVal={apTurnover}
                      industryAvg={(
                        365 / avgVals?.accountsPayableTurnoverRate
                      )?.toFixed(0)}
                    />
                  </CardWrapper>
                </Grid>
                <Box style={{ height: 30, width: "100%" }} />

                <CardWrapper border="1px solid #E7E7E7">
                  <Grid container xs={12}>
                    <Box style={{ height: 30, width: "100%" }} />
                    <Grid xs={12} ref={apTurnoverChartRef}>
                      <span
                        style={{
                          fontSize: 25,
                        }}
                      >
                        Historical Graph over time
                      </span>
                      <Box
                        // ref={trendsRef}
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            {/* using for AP turnover */}
                            <QuickRatioChart
                              quickRatio={historicalValues?.apTurnover}
                              dates={historicalValues?.dates}
                              label={"AP Days"}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Box style={{ height: 30, width: "100%" }} />
                    <div
                      ref={apTurnoverReportRef}
                      style={{
                        backgroundColor: "#EBF8FC",
                        padding: "20px",
                        borderRadius: "20px",
                      }}
                      className="full-width"
                    >
                      {/* <Box style={{ height: 30, width: "100%" }} /> */}
                      <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 16,
                          }}
                        >
                          <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                            }}
                          >
                            Analysis
                          </span>
                        </span>

                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bolder",
                            }}
                          >
                            {apTurnoverComparisonDetails?.headline}
                          </span>{" "}
                        </span>

                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Conclusions:
                          </span>{" "}
                          <Typography>
                            {apTurnoverComparisonDetails?.conclusion}
                          </Typography>
                        </span>
                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Suggestions:
                          </span>{" "}
                          <Typography>
                            {apTurnoverComparisonDetails?.suggestion}
                          </Typography>
                        </span>
                      </Typography>
                    </div>

                    <Grid item xs={12}>
                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <Grid container xs={12}>
                        <Accordian
                          noteData={apTurnoverNotes}
                          key1="apTurnoverChart"
                          selectedDate={selectedFinancialVals.year}
                          notesRef={apTurnoverNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardWrapper>
                <Box style={{ height: 30, width: "100%" }} />

                {/* AP turnover end here */}

                {/* Days cash on hand start here */}
                <Grid xs={12} ref={dcohGuageRef}>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 37,
                    }}
                  >
                    <Grid ref={dcohSection}>
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 25,
                        }}
                      >
                        {missingValError?.dcoh ? <MissingValTooltip /> : ""}
                        Days Cash On Hand:{" "}
                        {dcoh && dcoh !== Infinity ? dcoh : 0}
                      </span>
                      <Box style={{ height: 5, width: "100%" }} />
                      <span
                        style={{
                          color: "gray",
                          fontSize: 15,
                        }}
                      >
                        If sales stopped, how many days could you stay in
                        business.
                      </span>
                    </Grid>

                    <Grid
                      style={{
                        fontSize: 25,
                        display: "flex",
                      }}
                    >
                      Grade:{" "}
                      <TooltipMenu
                        getColor={getColor}
                        value={dcohGrade}
                        options={gradeOptions}
                        onChange={handleGradeChange}
                        calculation={DCOH}
                      />
                    </Grid>
                  </Grid>

                  <Box style={{ height: 20, width: "100%" }} />
                  <CardWrapper border="1px solid #E7E7E7">
                    {/* using for Days Cash On Hand */}
                    <CustomAppBarRoa
                      currentRatioScale={dcohScale}
                      currentVal={dcoh}
                    />
                  </CardWrapper>
                </Grid>
                <Box style={{ height: 30, width: "100%" }} />

                <CardWrapper border="1px solid #E7E7E7">
                  <Grid container xs={12}>
                    <Box style={{ height: 30, width: "100%" }} />
                    <Grid xs={12} ref={dcohChartRef}>
                      <span
                        style={{
                          fontSize: 25,
                        }}
                      >
                        Historical Graph over time
                      </span>
                      <Box
                        // ref={trendsRef}
                        style={{ width: "100%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            {/* using for Days Cash On Hand */}
                            <QuickRatioChart
                              quickRatio={historicalValues?.dcoh}
                              dates={historicalValues?.dates}
                              label={"Days Cash On Hand"}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Box style={{ height: 30, width: "100%" }} />
                    <div
                      ref={dcohReportRef}
                      style={{
                        backgroundColor: "#EBF8FC",
                        padding: "20px",
                        borderRadius: "20px",
                      }}
                      className="full-width"
                    >
                      {/* <Box style={{ height: 30, width: "100%" }} /> */}
                      <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 16,
                          }}
                        >
                          <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                            }}
                          >
                            Analysis
                          </span>
                        </span>

                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bolder",
                            }}
                          >
                            {dcohComparisonDetails?.headline}
                          </span>{" "}
                        </span>

                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Conclusions:
                          </span>{" "}
                          <Typography>
                            {dcohComparisonDetails?.conclusion}
                          </Typography>
                        </span>
                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Suggestions:
                          </span>{" "}
                          <Typography>
                            {dcohComparisonDetails?.suggestion}
                          </Typography>
                        </span>
                      </Typography>
                    </div>

                    <Grid item xs={12}>
                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <Grid container xs={12}>
                        <Accordian
                          noteData={dcohNotes}
                          key1="dcohChart"
                          selectedDate={selectedFinancialVals.year}
                          notesRef={dcohNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardWrapper>
                <Box style={{ height: 30, width: "100%" }} />

                {/* Days cash on hand end here */}

                {/* DSCR starts here */}

                <Grid xs={12} ref={dscrGuageRef}>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 37,
                    }}
                  >
                    <Grid>
                      <span
                        style={{
                          fontWeight: "bolder",
                          fontSize: 25,
                        }}
                        ref={dscrSection}
                      >
                        {missingValError?.dscr ? <MissingValTooltip /> : ""}
                        DSCR: {dscr && dscr !== Infinity ? dscr : 0}
                      </span>
                      <Box style={{ height: 5, width: "100%" }} />
                      <span
                        style={{
                          color: "gray",
                          fontSize: 15,
                        }}
                      >
                        Shows how well a company's profit can cover its debt
                        payments
                      </span>
                    </Grid>

                    <Grid
                      style={{
                        fontSize: 25,
                        display: "flex",
                      }}
                    >
                      Grade:{" "}
                      <TooltipMenu
                        getColor={getColor}
                        value={dscrGrade}
                        options={gradeOptions}
                        onChange={handleGradeChange}
                        calculation={DSCR}
                      />
                    </Grid>
                  </Grid>

                  <Box style={{ height: 20, width: "100%" }} />
                  <CardWrapper border="1px solid #E7E7E7">
                    <CustomAppBarRoa
                      currentRatioScale={dscrScale}
                      currentVal={dscr}
                    />
                  </CardWrapper>
                </Grid>
                <Box style={{ height: 30, width: "100%" }} />

                <CardWrapper border="1px solid #E7E7E7">
                  <Grid container xs={12}>
                    <Box style={{ height: 30, width: "100%" }} />
                    <Grid xs={12} ref={dscrChartRef}>
                      <span
                        style={{
                          fontSize: 25,
                        }}
                      >
                        Historical Graph over time
                      </span>
                      <Box style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <QuickRatioChart
                              quickRatio={historicalValues?.dscr}
                              dates={historicalValues?.dates}
                              label={"DSCR"}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Box style={{ height: 30, width: "100%" }} />
                    <div
                      ref={dscrReportRef}
                      style={{
                        backgroundColor: "#EBF8FC",
                        padding: "20px",
                        borderRadius: "20px",
                      }}
                      className="full-width"
                    >
                      {/* <Box style={{ height: 30, width: "100%" }} /> */}

                      <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 16,
                          }}
                        >
                          <img src={Analytics} style={{ marginRight: 10 }} />{" "}
                          <span
                            style={{
                              fontWeight: "bolder",
                              fontSize: 18,
                            }}
                          >
                            Analysis
                          </span>
                        </span>

                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bolder",
                            }}
                          >
                            {dscrComparisonDetails?.headline}
                          </span>{" "}
                        </span>

                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Conclusions:
                          </span>{" "}
                          <Typography>
                            {dscrComparisonDetails?.conclusion}
                          </Typography>
                        </span>
                        <span style={{ fontSize: 16, color: "#606060" }}>
                          <span
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            Suggestions:
                          </span>{" "}
                          <Typography>
                            {dscrComparisonDetails?.suggestion}
                          </Typography>
                        </span>
                      </Typography>
                    </div>

                    <Grid item xs={12}>
                      <Box style={{ height: 20, width: "100%" }} />
                      <Divider />
                      <Box style={{ height: 20, width: "100%" }} />
                      <Grid container xs={12}>
                        <Accordian
                          noteData={dscrNotes}
                          key1="dscrChart"
                          selectedDate={selectedFinancialVals.year}
                          notesRef={dscrNotesRef}
                          setIsGraphUpdate={setIsGraphUpdate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardWrapper>

                {/* DSCR ends here */}
              </Grid>
            </>
            // )
          }
        </CardWrapper>
      </Layout>
      <SnackBar open={openSnackbar} snackType={snackType} msg={message} />

      {Object.keys(selectedFinancialVals).length && (
        <ExpandLessIcon onClick={handleBackToTop} className="back_to_top_btn" />
      )}
    </React.Fragment>
  );
};
export default FinancialDoctor;
