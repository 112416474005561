// reducers/index.js

import { combineReducers } from "redux";
import companyReducers from "./company";
import sidenavReducers from "./sidenav";
import userReducers from "./user";
import snackbarReducers from "./snackbar";
import companyDetailReducers from "./companyDetails";
import notesReducers from "./notes";
import pdfGraphReducers from "./pdfGraph";
import financialDataReducers from "./financialDoctor";
import startWithEndReducer from "./startWithEnd";

const rootReducer = combineReducers({
  companyReducer: companyReducers,
  sidenavReducer: sidenavReducers,
  snackbarReducer: snackbarReducers,
  userReducer: userReducers,
  companyDetailReducer: companyDetailReducers,
  notesReducer: notesReducers,
  pdfGraphReducer: pdfGraphReducers,
  startWithEndReducer: startWithEndReducer,
  financialDoctorReducer: financialDataReducers,
});

export default rootReducer;
